import { EmployeeDetail, EmpoyeeModel } from "app/modules/components/employeeDetail/employeeDetail";
import axios from "axios";
import * as Yup from 'yup';
import parse from 'html-react-parser';
import { FinalSettlement, initialTransaction } from "./model";
import { FC, ReactElement, useEffect, useRef, useState } from "react";
import { Formik, FormikProps, FormikValues } from 'formik'
import { GetFinalSettlementById, MODEL_NAME, DeleteFinalSettlement } from "./_requests";
import moment from "moment";
import './style.scss'
import "react-datepicker/dist/react-datepicker.css";
import AzkActionButtons from "_azkatech/azkActionButtons";
import { Form, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { ID } from "_metronic/helpers";
import { useNavigate } from "react-router";
import { API_URL } from "_azkatech/azkHelper";
import { AZKHelper } from "app/modules/components/azkHelper";
import { AzkChatter } from "_azkatech";
import { checkTrnsactionTypeAccessId } from "app/utilities/transactionTyperequests";
import { GetEmployeeById } from "app/modules/components/employeeDetail/_requests";
import { useParams } from "react-router-dom";
import { AZKTimeLine } from "_azkatech/azkTimeLine";
import ReactDOM from 'react-dom';
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import reactElementToJSXString from "react-element-to-jsx-string";
import FinalSettlementParametersModal from "../final-settlement-parameters/form";
const converter = require('number-to-words');

type Props = {
}
const editTransactionSchema = Yup.object().shape({
    employee_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Employee is required'),
})

let employeeName = ''
const Transactionform: FC<Props> = () => {
    const { id } = useParams();
    const Swal = require('sweetalert2')
    const intl = useIntl();
    const navigate = useNavigate()
    const formRef = useRef<FormikProps<FinalSettlement>>(null);
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [transactionClosed, setTransactionClosed] = useState<boolean>(false);
    const [fsObject, setFsObject] = useState<FinalSettlement>();

    const [canDelete, setCanDelete] = useState<boolean>(false);
    const [canValidate, setCanValidate] = useState<boolean>(false);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [allowedParamters, setAllowedParamters] = useState<string>('');
    const [canApprove, setCanApprove] = useState<boolean>(false);
    // const [canReject, setCanReject] = useState<boolean>(false);
    // const [canReview, setCanReview] = useState<boolean>(false);
    // const [canRegenerate, setCanRegenerate] = useState<boolean>(false);
    // const [canView, setCanView] = useState<boolean>(false);
    // const [canDownloadPDF, setCanDownloadPDF] = useState<boolean>(false);
    // const [canUploadPDF, setCanUploadPDF] = useState<boolean>(false);
    // const [canToPay, setCanToPay] = useState<boolean>(false);
    // const [canCompleted, setCanCompleted] = useState<boolean>(false);
    // const [canC2PPartialDone, setCanC2PPartialDone] = useState<boolean>(false);
    const [regenerateRequested, setRegenerateRequested] = useState<boolean>(false);

    const [employee, setEmployee] = useState<EmpoyeeModel>();
    const [transactionState, setTransactionState] = useState<string>('draft');
    const [clearanceTransactionId, setClearanceTransactionId] = useState<number>();
    const [transactionStateName, setTransactionStateName] = useState<string>('Draft');
    const [hitSend, setHitSend] = useState<boolean>(false);
    const [refreshChatter, setRefreshChatter] = useState<boolean>(true);
    // const [objectId, setObjectId] = useState<number | undefined>();
    const [transactionSteps, setTransactionSteps] = useState<[{ id?: number, name?: string }]>();
    const [allowedButttons, setAllowedButttons] = useState<any[]>([]);
    const handleEmployeeChange = async function (employeeId, finalSettlementData): Promise<{}> {
        document.body.classList.add('page-loading')
        if (employeeId) {
            setEmployee(undefined)
            GetEmployeeById(employeeId).then(response => {
                if (response) {
                    const tempEmployee = response
                    if (id || !tempEmployee.has_running_transaction) {
                        setEmployee(response)
                        if(response.name){
                            employeeName = response.name
                        }
                        formRef.current?.setValues({
                            employee_id: tempEmployee.id,
                            az_country_id: tempEmployee.az_country_id?.id,
                            az_company_id: tempEmployee.az_company_id?.id,
                            payroll_id: tempEmployee.payroll_id?.id,
                            branch_id: tempEmployee.branch_id?.id,
                            brand_id: tempEmployee.brand_id?.id,
                            grade_id: tempEmployee.grade_id?.id,
                            section_id: tempEmployee.section_id?.id,
                            job_id: tempEmployee.job_id?.id,
                            as_effective_date: moment(tempEmployee.as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT),
                            time_set: tempEmployee.time_set,
                            salary: tempEmployee.salary,
                            // target_salary:  tempEmployee.salary,
                            target_as_effective_date: formRef.current.values.target_as_effective_date ? moment(formRef.current.values.target_as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT) : moment().format(AZKHelper.FRONTEND_DATE_FORMAT),
                        })
                        if (finalSettlementData) pobulateFormData(finalSettlementData)
                        document.body.classList.remove('page-loading')
                    } else {
                        document.body.classList.remove('page-loading')
                        Swal.fire({
                            position: "top-right",
                            title: 'Sorry!',
                            text: 'This employee ' + tempEmployee.name + ' already has an open transaction with details ' + tempEmployee.running_transaction + '!',
                            icon: "error",
                            showConfirmButton: false,
                            timer: 5000,
                            toast: true,
                            iconColor: 'white',
                            customClass: {
                                popup: 'colored-toast'
                            },
                            timerProgressBar: true
                        })
                    }
                } else {
                    document.body.classList.remove('page-loading')
                }
            });
        } else {
            document.body.classList.remove('page-loading')
            formRef.current?.resetForm();
            setEmployee(undefined)
            formRef.current?.setValues({
                employee_id: undefined,
                az_country_id: undefined,
                az_company_id: undefined,
                payroll_id: undefined,
                branch_id: undefined,
                brand_id: undefined,
                grade_id: undefined,
                section_id: undefined,
                job_id: undefined,
                as_effective_date: undefined,
                time_set: undefined,
                salary: undefined
            })
        }
        return true
    }

    const pobulateFormData = async (data?: FinalSettlement) => {
        const buttons = [{}]
        buttons.pop();
        if (data) {
            setFsObject(data)
            if (data?.steps) setTransactionSteps(data.steps)
            setAllowedParamters(data?.allowed_paramters ? data?.allowed_paramters : '')
            if (data?.can_approve && data.can_review && !data?.need_validation) {
                setCanApprove(true)
                buttons.push({ title: "Approve", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.APPROVED' }), method: "validate_tier", confirmMsg: "Approve Transaction, Confirm?", cssClass: "btn btn-light btn-light-success", icon: "fa-thumbs-up" })
            }
            setFsObject(data)
            if (data?.can_reject && data.can_review && !data?.need_validation) {
                // setCanReject(true)
                buttons.push({ title: "Decline", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.DECLINE' }), method: "reject_tier", confirmMsg: "Decline Transaction, Confirm?", cssClass: "btn btn-light btn-light-danger", icon: "fa-times" })
            }
            if (data?.can_review_fs && data.can_review && !data?.need_validation) {
                // setCanReview(true)
                buttons.push({ title: "Review", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.REVIEW' }), method: "restart_validation", confirmMsg: "Send Transaction back to draft, Confirm?", cssClass: "btn btn-light btn-light-warning", icon: "fa-refresh" })
            }
            if (data?.can_regenerate && data.can_review && !data?.need_validation) {
                // setCanRegenerate(true)
                buttons.push({ title: "Regenerate", confirmMsg: undefined, cssClass: "btn btn-light btn-light-warning", icon: "fa-cogs", callback: regenerateFS })
            }
            if (data?.can_view) {
                // setCanView(true)
                buttons.push({ title: "View", confirmMsg: undefined, cssClass: "btn btn-light btn-light-warning", icon: "fa-eye", callback: viewReport })
            }
            // if (data?.can_download_pdf && !data?.need_validation) {
            // setCanDownloadPDF(true)
            buttons.push({ title: "Download PDF", cssClass: "btn btn-light btn-light-warning", icon: "fa-download", callback: downloadFSPDF })
            // }
            if (data?.can_upload_pdf && data.can_review && !data?.need_validation && !data?.marked_file_uploaded) {
                // setCanUploadPDF(true)
                buttons.push({ title: "Upload PDF", confirmMsg: undefined, cssClass: "btn btn-light btn-light-warning", icon: "fa-upload", callback: openAttachWizard })
            }
            if (data?.can_to_pay && data.can_review && !data?.need_validation && data?.marked_file_uploaded) {
                // setCanToPay(true)
                buttons.push({ title: "To pay", method: "to_pay", confirmMsg: undefined, cssClass: "btn btn-light btn-light-warning", icon: "fa-credit-card" })
            }
            if (data?.can_completed && data.can_review && !data?.need_validation && data?.marked_file_uploaded && data.marked_to_pay) {
                // setCanCompleted(true)
                buttons.push({ title: "Completed", method: "completed", confirmMsg: undefined, cssClass: "btn btn-light btn-light-warning", icon: "fa-check" })
            }
            if (data?.can_c2p_partial_done && data.can_review && !data?.need_validation && data?.marked_file_uploaded && data?.partial_payment) {
                // setCanC2PPartialDone(true)
                buttons.push({ title: "C2P Partial Done", method: "c2p_partial_done", confirmMsg: undefined, cssClass: "btn btn-light btn-light-warning", icon: "fa-hourglass-end" })
            }
            if (data?.can_reset_upload && data.can_review && !data?.need_validation && data?.marked_file_uploaded && !data.marked_to_pay && !data?.partial_payment) {
                buttons.push({ title: "Reset upload file", method: "reset_upload", confirmMsg: undefined, cssClass: "btn btn-light btn-light-warning", icon: "fa-hourglass-end" })
            }
            formRef.current?.setValues({
                id: data.id,
                employee_id: data.employee_id?.id,
                az_country_id: data.az_country_id?.id,
                az_company_id: data.az_company_id?.id,
                payroll_id: data.payroll_id?.id,
                branch_id: data.branch_id?.id,
                brand_id: data.brand_id?.id,
                grade_id: data.grade_id?.id,
                section_id: data.section_id?.id,
                job_id: data.job_id?.id,
                as_effective_date: moment(data.as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT),
                time_set: data.time_set,
                salary: data.salary,
                azadea_employee_name: data.azadea_employee_name,
                salary_day: data.salary_day,
                notice_period: data.notice_period,
                leave_salary: data.leave_salary,
                indemnity: data.indemnity,
                adjustment: data.adjustment,
                other_deduction: data.other_deduction,
                other_addition: data.other_addition,
                ticket_amount: data.ticket_amount,
                ticket_amount_val: data.ticket_amount_val,
                indemnity_zero: data.indemnity_zero ? true : false,
                partial_payment: data.partial_payment ? true : false,
                bankname: data.bankname,
                iban_number: data.iban_number,
                swift_code: data.swift_code,
                bankcode: data?.bankcode,
                remark: (data.remark !== 'False' && data.remark !== 'false') ? data.remark : '',
                comment: data.comment,
                housing_all: data?.housing_all,
                trans_all: data?.trans_all,
                monthly_working_days: data?.monthly_working_days,
                eos_settlement: data?.eos_settlement,
                annual_leave_settlement: data?.annual_leave_settlement,
                ticket_encashment_settlement: data?.ticket_encashment_settlement,
                commission_settlement: data?.commission_settlement,
                paid_amount1: data?.paid_amount1,
                due_date1: data?.due_date1,
                paid_amount2: data?.paid_amount2,
                due_date2: data?.due_date2,
                indemnity_year: data?.indemnity_year,
                target_as_effective_date: moment(data?.target_as_effective_date).format(AZKHelper.FRONTEND_DATE_FORMAT),
                basic_salary: data.basic_salary,
                housing: data.housing,
                other_allowance: data.other_allowance,
                working_days: data.working_days,
                salary_days: data.salary_days,
                housing_days: data.housing_days,
                transportation_days: data.transportation_days,
                commission: data.commission,
                notice_period_days: data.notice_period_days,
                salary_notice_period: data.salary_notice_period,
                housing_notice_period: data.housing_notice_period,
                transportation_notice_period: data.transportation_notice_period,
                leave_days: data.leave_days,
                leave_amount: data.leave_amount,
                indemnity_days: data.indemnity_days,
                indemnity_amount: data.indemnity_amount,
                net_to_pay: data.net_to_pay,
                eos_extra_hours: data.eos_extra_hours,
                eos_public_holiday: data.eos_public_holiday,
                end_nature: data.end_nature,
                joining_date: data.joining_date,
                end_date: data.end_date,
                hrms_status: data.hrms_status,
                other_addition_amount: data.other_addition_amount,
                other_deduction_amount: data.other_deduction_amount,
            })
            setFsObject(data)

            setAllowedButttons(buttons)
        }
    }

    const openAttachWizard = () => {
        const inputElement = document.querySelector('#file') as HTMLInputElement
        inputElement.click()
    }

    const checkPermission = async (action: string) => {
        const allowed = await checkTrnsactionTypeAccessId(MODEL_NAME, action)
        if (!allowed) {
            if (id) {
                const allowed_read = await checkTrnsactionTypeAccessId(MODEL_NAME, 'read')
                if (!allowed_read) {
                    navigate(`/transactions/`, { state: {}, replace: true });
                    Swal.fire({
                        position: "top-right",
                        title: 'Access Error!',
                        text: 'You does not have access to perform read action!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                } else {
                    setReadOnly(true)
                }
            } else {
                navigate(`/transactions/`, { state: {}, replace: true });
                Swal.fire({
                    position: "top-right",
                    title: 'Access Error!',
                    text: 'You does not have access to perform this action!',
                    icon: "error",
                    showConfirmButton: false,
                    timer: AZKHelper.SWAL_TOAST_TIMER,
                    toast: true,
                    iconColor: 'white',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    timerProgressBar: true
                })
            }
        }
    }

    const updateForm = (id) => {
        employeeName = ''
        GetFinalSettlementById(parseInt(id)).then(((data: FinalSettlement) => {
            setClearanceTransactionId(data?.clearance_trn_id.id)
            handleEmployeeChange(data?.employee_id?.id, data)
            if (data?.x_state && data?.x_state_view) {
                setTransactionState(data?.x_state)
                setTransactionStateName(data?.x_state_view)
            }
            if (data?.can_write && data?.can_write !== null) {
                setCanEdit(data?.can_write)
            }
            setTransactionClosed(data.validated || data.rejected ? true : false)
            setCanValidate(data?.can_review ? true : false)
            setCanDelete(data?.can_delete ? true : false)
            document.body.classList.remove('page-loading')
        }))
    }

    useEffect(() => {
        formRef.current?.resetForm()
        if (id) {
            document.body.classList.add('page-loading')
            checkPermission('write');
            updateForm(id)
        } else {
            navigate(`/transactions/`, { state: {}, replace: true });
            Swal.fire({
                position: "top-right",
                title: 'Access Error!',
                text: 'You does not have access to perform this action!',
                icon: "error",
                showConfirmButton: false,
                timer: AZKHelper.SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
        }
    }, [])

    const cancel = () => {
        navigate(`/transactions/`, { state: {}, replace: true });
    }

    const deleteTransaction = () => {
        Swal.fire({
            position: "center",
            title: 'Delete',
            text: 'Delete Transaction confirm?',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed && id) {
                DeleteFinalSettlement(parseInt(id)).then(() => {
                    cancel()
                    Swal.fire({
                        position: "top-right",
                        title: 'Deleted!',
                        text: 'Your transaction had been deleted!',
                        icon: "warning",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                })
            } else if (result.isDenied) {
                Swal.fire('Transaction was not deleted', '', 'info')
            }
        })
    }

    const submitTransaction = (transactionId: number) => {
        // setNeedSubmit(false)
        axios.put(`${API_URL}/${MODEL_NAME}/${transactionId}/request_validation`, '{}').then(data => {
            document.body.classList.remove('page-loading')
            navigate(`/transactions/`, { state: {}, replace: true });
            Swal.fire({
                position: "top-right",
                title: 'Success!',
                text: 'Your transaction had been submited!',
                icon: "success",
                showConfirmButton: false,
                timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
        })
    }

    const handleResultOfsubmit = (transactionId: ID | undefined, isUpdate: boolean | false) => {
        // if (needSubmit && transactionId) {
        if (transactionId) {
            submitTransaction(transactionId)
        } else {
            document.body.classList.remove('page-loading')
            navigate(`/transactions/`, { state: {}, replace: true })
            Swal.fire({
                position: "top-right",
                title: 'Success!',
                text: isUpdate ? 'Your transaction had been updated!' : 'Your transaction had been saved!',
                icon: "success",
                showConfirmButton: false,
                timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
        }
    }

    const onSubmitHandler = async (values: FinalSettlement, actions: FormikValues) => {
        actions.setSubmitting(true);
        formRef.current?.validateForm()
        if (formRef.current?.isValid) {
        } else {
        }
    }

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        setRefreshChatter(false)
        document.body.classList.add('page-loading')
        if (files) {
            await Array.from(files).forEach((file, index) => {
                const fileType = file.type;
                if (AZKHelper.ALLOWED_DOC_TYPES.includes(fileType)) {
                    const reader = new FileReader();
                    reader.onloadend = async () => {
                        const arrayBuffer = reader.result;
                        let datas = ''
                        if (arrayBuffer instanceof ArrayBuffer) {
                            let binary = '';
                            const uint8Array = new Uint8Array(arrayBuffer);
                            const len = uint8Array.byteLength;
                            for (let i = 0; i < len; i++) {
                                binary += String.fromCharCode(uint8Array[i]);
                            }
                            datas = window.btoa(binary)
                        } else if (arrayBuffer) {
                            datas = arrayBuffer.split(',')[1]
                        }
                        const formData = new FormData();
                        formData.append('res_model', MODEL_NAME);
                        if (id) formData.append('res_id', '' + parseInt(id));
                        formData.append('name', 'FS' + file.name);
                        formData.append('type', "binary");
                        formData.append('datas', datas);
                        axios.post(`${API_URL}/attachments/upload`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then(response => {
                            if (id && response && response.data)
                                axios.put(`${API_URL}/${MODEL_NAME}/${parseInt(id)}/upload_pdf`, { 'attachment_id': response.data['id'] }).then(response => {
                                    updateForm(parseInt(id))
                                    setRefreshChatter(true)
                                    document.body.classList.remove('page-loading')
                                    Swal.fire({
                                        position: "top-right",
                                        title: 'Success!',
                                        text: 'The FS had been uploaded!',
                                        icon: "success",
                                        showConfirmButton: false,
                                        timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                                        toast: true,
                                        iconColor: 'white',
                                        customClass: {
                                            popup: 'colored-toast'
                                        },
                                        timerProgressBar: true
                                    })
                                }, err => {
                                    setRefreshChatter(true)
                                    document.body.classList.remove('page-loading')
                                })
                            return response.data.id
                        }).catch(function (error) {
                            console.log(error)
                            setRefreshChatter(true)
                            document.body.classList.remove('page-loading')
                            return error;
                        })
                    }
                    reader.readAsArrayBuffer(file);
                } else {
                    setRefreshChatter(true)
                    document.body.classList.remove('page-loading')
                    Swal.fire({
                        position: "top-right",
                        title: 'Sorry!',
                        text: 'Please check you attachments, allowed file types (' + AZKHelper.ALLOWED_DOC_TYPE + ')!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: 5000,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                };
            })
        }
    }

    const regenerateFS = async () => {
        Swal.fire({
            position: "center",
            title: "Regenerate",
            text: "Are you sure you want ot regenerate the FS?",
            showCancelButton: true,
            confirmButtonText: 'Confirm',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                setRegenerateRequested(true)
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'info')
            }
        })
    }

    const viewReport = async () => {
        if (id) {
            document.body.classList.add('page-loading')
            GetFinalSettlementById(parseInt(id)).then(async (data: FinalSettlement) => {
                let html_obj;
                if (data.template === 'bahrain') {
                    html_obj = generate_reportBahrain(data)
                } else if (data.template === 'other') {
                    html_obj = generate_report(data)
                } else if (data.template === 'kuwait') {
                    html_obj = generate_reportKuwait(data)
                } else if (data.template === 'arabic') {
                    html_obj = generate_reportBahrain(data)
                }
                html_obj.then(async html_obj => {
                    if (html_obj) {
                        const newTab = window.open('', '_blank');
                        let html_st = await reactElementToJSXString(html_obj);
                        html_st = html_st.replaceAll('className', 'class')
                        if (newTab) {
                            newTab.document.write(`
                            <html>
                            <head>
                                <title>Final Settlement</title>
                                <style>
                                    .box-container-boarder {
                                        border: 1px solid black;
                                        border-collapse: collapse;
                                    }
                                    body{
                                        font-family: 'Arial';
                                    }
                                </style>
                            </head>
                            <style>
                                .box-container-boarder {
                                    border: 1px solid black;
                                    border-collapse: collapse;
                                }
                                body{
                                    font-family: 'Arial';
                                }
                            </style>
                            <body>
                                ${html_st}
                            </body>
                            </html>
                        `);
                            newTab.document.close();
                        }
                        document.body.classList.remove('page-loading')
                    } else {
                        document.body.classList.remove('page-loading')
                    }
                })
            })
        }
    }

    const downloadFSPDF = () => {
        if (id) {
            document.body.classList.add('page-loading')
            GetFinalSettlementById(parseInt(id)).then(async (data: FinalSettlement) => {

                let html_obj;
                if (data?.template === 'bahrain') {
                    html_obj = generate_reportBahrain(data)
                } else if (data?.template === 'other') {
                    html_obj = generate_report(data)
                } else if (data?.template === 'arabic') {
                    html_obj = generate_reportBahrain(data)
                }
                html_obj.then(async html_obj => {
                    if (html_obj) {
                        const options = {
                            // method: 'open', // 'save' or 'open'
                            resolution: Resolution.HIGH,
                            page: {
                                margin: Margin.SMALL,
                                format: 'A4',
                                //   orientation: 'landscape',
                            },
                            canvas: {
                                //   mimeType: 'image/png',
                                qualityRatio: 1,
                            },
                            overrides: {
                                pdf: { compress: false },
                                canvas: { useCORS: true },
                            },
                        };
                        const parentElement = document.createElement('div');
                        const element =
                            <div>
                                <button onClick={() => generatePDF(getTargetElement, options)} id="export_data_pdf">Generate PDF</button>
                                <div id="content-id">
                                    {html_obj}
                                </div>
                            </div>
                        ReactDOM.render(element, parentElement);
                        document.body.appendChild(parentElement)
                        const inputElement = document.querySelector('#export_data_pdf') as HTMLInputElement
                        inputElement.click()
                        document.body.removeChild(parentElement)
                        document.body.classList.remove('page-loading')
                        document.body.classList.remove('page-loading')
                    }
                })
            })
        }
    }

    const generate_reportBahrain = async (fs) => {
        if (!fs?.eos_public_holiday) {
            fs.eos_public_holiday = '0'
        }
        if (!fs?.eos_extra_hours) {
            fs.eos_extra_hours = '0'
        }
        fs.remark = fs.remark.trim()
        if (fs.remark === 'False' || fs.remark === 'false') {
            fs.remark = ''
        }
        let deduction_itme: any = []
        let totla_deduction_amount = 0
        await fs?.deductions?.map((deduction, index) => {
            totla_deduction_amount += Math.abs(parseFloat('0' + deduction.amount))
            deduction_itme[index] = <tr>
                <td width="60%" align="left">{deduction.element_name}</td>
                <td width="40%" align="left">{parseFloat(deduction?.amount)?.toFixed(2)}</td>
            </tr>
        })
        if(fs.adjustment && parseFloat('' + fs.adjustment) < 0){
            deduction_itme.push(<tr>
                <td width="60%" align="left">Other Deductions</td>
                <td width="40%" align="left">{parseFloat('' + fs.adjustment)?.toFixed(2)}</td>
            </tr>)
        }
        if(fs.other_deduction_amount && Math.abs(parseFloat('' + fs.other_deduction_amount)) > 0){
            totla_deduction_amount += Math.abs(parseFloat('' + fs.other_deduction_amount))
            deduction_itme.push(<tr>
                <td width="60%" align="left">Other Deductions</td>
                <td width="40%" align="left">{Math.abs(parseFloat('' + fs.other_deduction_amount))?.toFixed(2)}</td>
            </tr>)
        }
        return (
            <div>
                <table width="100%">
                    <tr>
                        <td align="center"><h2><u>Final Settlement</u></h2></td>
                    </tr>
                </table>
                <table width="100%" className="box-container-boarder" style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                    <tr style={{ border: '1px soild #000' }} >
                        <td width="50%" align="left"><strong>Settlement No:</strong>&nbsp;&nbsp;&nbsp;{fs?.serial_number}</td>
                        <td width="50%" align="right"><strong>Date:</strong>&nbsp;&nbsp;&nbsp;{moment(moment.now()).format(AZKHelper.REPORT_DATE_FORMAT)}</td>
                    </tr>
                    <tr className="box-container-boarder" style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                        <td className="box-container-boarder" style={{ border: '1px solid black', borderCollapse: 'collapse' }} width="50%">
                            <table width="100%" >
                                <tr>
                                    <td width="50%"><b>Employee ID - Name</b></td>
                                    <td width="50%">{fs?.azadea_id} - {employeeName}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>Job Title</b></td>
                                    <td width="50%">{fs?.job_id.name}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>Company</b></td>
                                    <td width="50%">{fs?.az_company_id.name}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>Shop/Dept Name</b></td>
                                    <td width="50%">{fs?.branch_id.name}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>Ending Nature</b></td>
                                    <td width="50%">{fs?.end_nature}</td>
                                </tr>
                            </table>
                        </td>
                        <td width="50%" className="box-container-boarder" style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                            <table width="100%">
                                <tr>
                                    <td width="50%"><b>Joining Date :</b></td>
                                    <td width="50%">{moment(fs?.joining_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>Ending Date :</b></td>
                                    <td width="50%">{moment(fs?.end_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>Actual Last working Date</b></td>
                                    <td width="50%">{moment(fs?.actual_termination_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>Non Service Days</b></td>
                                    <td width="50%"><span color={"#010101"}>0.00</span></td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>Indemnity Years</b></td>
                                    <td width="50%"><span color={'red'}>0.00</span></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr >
                        <td width="50%" className="box-container-boarder" style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                            <table width="100%">
                                <tr>
                                    <td width="25%"><strong><u>Description</u></strong></td>
                                    <td width="25%"><strong><u>Entitlements</u></strong></td>
                                    <td width="25%"><strong><u>No of Days</u></strong></td>
                                    <td width="25%"><strong><u>Payable Amount</u></strong></td>
                                </tr>
                                <tr>
                                    <td width="25%">Leave Salary</td>
                                    <td width="25%"></td>
                                    <td width="25%">{parseFloat(fs?.leave_days).toFixed(2)}</td>
                                    <td width="25%">{parseFloat(fs?.leave_amount).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td width="25%"></td>
                                    <td width="25%"></td>
                                    <td width="25%"></td>
                                    <td width="25%"></td>
                                </tr>
                                <tr>
                                    <td width="25%"><strong><u>Description</u></strong></td>
                                    <td width="25%"></td>
                                    <td width="25%"><strong><u>No of Years</u></strong></td>
                                    <td width="25%"><strong><u>Payable Amount</u></strong></td>
                                </tr>
                                <tr>
                                    <td width="25%">Indemnity</td>
                                    <td width="25%"></td>
                                    {/* {fs?.indemnity_days && <td width="25%">{parseFloat(fs?.indemnity_days).toFixed(2)}</td>} */}
                                    <td width="25%">{parseFloat(fs?.indemnity_year).toFixed(2)}</td>
                                    <td width="25%">{parseFloat(fs?.indemnity_amount).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td width="25%">Notice Period</td>
                                    <td width="25%"></td>
                                    <td width="25%"></td>
                                    <td width="25%">{parseFloat(fs?.salary_notice_period).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td width="25%">Extra Hours1</td>
                                    <td width="25%"></td>
                                    <td width="25%"></td>
                                    <td width="25%">{parseFloat(fs?.eos_extra_hours)?.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td width="25%">Public Holiday</td>
                                    <td width="25%"></td>
                                    <td width="25%"></td>
                                    <td width="25%">{parseFloat(fs?.eos_public_holiday)?.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td width="25%">Other Additions</td>
                                    <td width="25%"></td>
                                    <td width="25%"></td>
                                    <td width="25%">{(parseFloat(fs?.other_addition_amount) + parseFloat(fs?.adjustment))?.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td width="25%">Ticket</td>
                                    <td width="25%"></td>
                                    <td width="25%"></td>
                                    <td width="25%">{parseFloat(fs?.ticket_amount)?.toFixed(2)}</td>
                                </tr>
                            </table>
                        </td>
                        <td width="50%" className="box-container-boarder" style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                            <table width="100%">
                                <tr>
                                    <td width="60%"><b><u>Description</u></b></td>
                                    <td width="40%"><b><u>Deductable Amt</u></b></td>
                                </tr>
                                {deduction_itme?.map(item => {
                                    return item
                                })}
                            </table>
                        </td>
                    </tr>
                    <tr className="box-container-boarder" style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                        <td width="50%">
                            <br />
                            <table width="100%">
                                <tr><td width="75%"><b>Total Payable AMT</b></td>
                                    <td width="25%">
                                        {(parseFloat(fs.leave_amount) + parseFloat(fs.indemnity_amount) + parseFloat(fs.salary_notice_period) + parseFloat(fs.eos_extra_hours) + parseFloat(fs.eos_public_holiday) + parseFloat(fs.other_addition_amount) + parseFloat(fs.ticket_amount) + parseFloat(fs?.adjustment)).toFixed(2)}
                                    </td></tr>
                            </table>
                        </td>
                        <td width="50%">
                            <br />
                            <table width="100%">
                                <tr><td width="60%"><b>Total Deductable AMT</b></td>
                                    <td width="40%">
                                        {parseFloat('0' + totla_deduction_amount)?.toFixed(2)}
                                    </td></tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Total Amount: {fs?.net_to_pay ? converter.toWords(parseFloat(fs?.net_to_pay)?.toFixed(2)) : ''}</p></td>
                        <td><p>{parseFloat(fs?.net_to_pay)?.toFixed(2)}</p></td>
                    </tr>
                </table>

                <table width="100%" >
                    <br />
                    <tr>
                        <td width="15%" align="left"><strong>Bank:</strong></td>
                        <td width="35%" align="left">{fs?.bankname}</td>
                        <td width="15%" align="left"><strong>Account:</strong></td>
                        <td width="35%" align="left">{fs?.iban_number}</td>
                    </tr>
                    <tr>
                        <td width="15%" align="left"><strong>Remarks:</strong></td>
                        <td width="35%" align="left">{fs?.remark}</td>
                        <td width="15%" align="left"><strong>Swift Code:</strong></td>
                        <td width="35%" align="left">{fs?.swift_code}</td>
                    </tr>
                </table><br /><br />
                <table width="100%">
                    <tr>
                        <td width="25%" align="left"><strong>HR Dept.</strong><br /><br /><br /></td>
                        <td width="25%" align="left"></td>
                        <td width="25%" align="left"></td>
                        <td width="25%" align="left"><strong>Accounts Dept.</strong></td>
                    </tr>
                </table><br /><br />
                <p>
                    Declaration: I confirm that I have received the above total amount in full and final settlement of all the dues in full and all wages and salaries and all compensations and indemnities due to me for my entire period of service with the said company from {moment(fs?.date_group_start_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)} up to {moment(fs?.end_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}
                </p>
                <br /><br />
                <table width="100%">
                    <tr>
                        <td width="25%" align="left"></td>
                        <td width="25%" align="left"></td>
                        <td width="25%" align="left"></td>
                        <td width="25%" align="left"><hr /><strong>Beneficiary Signature</strong></td>

                    </tr>
                </table>
            </div>
        )
    }

    const generate_report = async (fs) => {
        fs.remark = fs.remark.trim()
        if (fs.remark === 'False' || fs.remark === 'false') {
            fs.remark = ''
        }
        fs.date_group_start_date = moment(fs?.date_group_start_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)
        fs.end_date = moment(fs?.end_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)
        const current_date = moment(moment.now()).format("DD MMM yyyy")
        let deduction_itme: any = []
        await fs?.deductions?.map((deduction, index) => {
            deduction_itme[index] = <tr>
                <td width="25%" align="left">{index === 0 ? "6. Deductions..." : ""}</td>
                <td width="10%" align="right"></td>
                <td width="25%" align="left">{deduction.element_name}</td>
                <td width="20%" align="left">{deduction.amount.toFixed(2)}</td>
                <td width="20%" align="left">
                    {index === fs?.deductions.length - 1 && fs?.deductions.reduce((accumulator, item) => accumulator + item.amount, 0).toFixed(2)}
                </td>
            </tr>
        })
        fs.fs_letter = fs?.fs_letter.replace('this </b> <BR><BR>\n<BR>', `this`)
        const fs_letter = parse('<p>' + fs?.fs_letter.replace('conditions thereof on this', `conditions thereof on this <strong>`) + moment(moment.now()).format("DD MMM yyyy") + '</strong></p>')
        let html_st = <div >
            <table width="100%">
                <tr>
                    <td align="left"></td>
                    <td align="right"><strong>{moment(moment.now()).format("DD MMM yyyy")}</strong></td>
                </tr>
            </table>
            <table width="100%" >
                <tr>
                    <td width="60%"><strong>Final Settlement</strong></td>
                    <td width="25%"><strong>Settlement No.</strong></td>
                    <td width="15%">{parseInt(fs?.serial_number)}</td>
                </tr>
            </table>
            <hr />
            <table width="100%">
                <tr>
                    <td width="60%">{fs?.azadea_id}</td>
                    <td width="25%"><strong></strong></td>
                    <td width="15%"></td>
                </tr>
                <tr>
                    <td width="60%">{employeeName}</td>
                    <td width="25%"><strong><strong>Joining Date:</strong></strong></td>
                    <td width="15%">{moment(fs?.joining_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}</td>
                </tr>
                <tr>
                    <td width="60%">{fs?.job_id.name}</td>
                    <td width="25%"><strong>Ending Date:</strong></td>
                    <td width="15%">{fs?.end_date}</td>
                </tr>
                <tr>
                    <td width="60%">{fs?.az_company_id.name}</td>
                    <td width="25%"><strong>Basic Salary:</strong></td>
                    <td width="15%">{parseFloat(fs?.basic_salary)?.toFixed(2)}</td>
                </tr>
                <tr>
                    <td width="60%">{fs?.branch_id.name}</td>
                    <td width="25%"><strong>Housing Allowance:</strong></td>
                    <td width="15%">{parseFloat(fs?.housing_all)?.toFixed(2)}</td>
                </tr>
                <tr>
                    <td width="60%">Ending Nature:{fs?.end_nature}</td>
                    <td width="25%"><strong>Other Allowance:</strong></td>
                    <td width="15%">{parseFloat(fs?.trans_all)?.toFixed(2)}</td>
                </tr>
            </table>
            <hr />
            <table width="100%">
                <tr>
                    <td width="25%" align="left">1. Salary Days -</td>
                    <td width="10%" align="center">{fs?.working_days}</td>
                    <td width="25%" align="left">Basic Salary</td>
                    <td width="20%" align="left">{parseFloat(fs?.salary_days)?.toFixed(2)}</td>
                    <td width="20%" align="left"></td>
                </tr>
                <tr>
                    <td width="25%" align="left"></td>
                    <td width="10%" align="right"></td>
                    <td width="25%" align="left">Housing Allowance</td>
                    <td width="20%" align="left">{parseFloat(fs?.housing_days)?.toFixed(2)}</td>
                    <td width="20%" align="left"></td>
                </tr>
                <tr>
                    <td width="25%" align="left"></td>
                    <td width="10%" align="right"></td>
                    <td width="25%" align="left">Other Allowance</td>
                    <td width="20%" align="left">{parseFloat(fs?.transportation_days)?.toFixed(2)}</td>
                    <td width="20%" align="left"></td>
                </tr>
                <tr>
                    <td width="25%" align="left"></td>
                    <td width="10%" align="right"></td>
                    <td width="25%" align="left">Commission</td>
                    <td width="20%" align="left">{parseFloat(fs?.commission)?.toFixed(2)}</td>
                    <td width="20%" align="left">{(parseFloat(fs?.salary_days) + parseFloat(fs?.housing_days) + parseFloat(fs?.transportation_days) + parseFloat(fs?.commission))?.toFixed(2)}</td>
                </tr>
            </table>
            <br /><br />
            <table width="100%" >
                <tr>
                    <td width="25%" align="left">2. Notice Period Days -</td>
                    <td width="10%" align="center">{parseFloat(fs?.notice_period_days)?.toFixed(2)}</td>
                    <td width="25%" align="left">Basic Salary</td>
                    <td width="20%" align="left">{parseFloat(fs?.salary_notice_period)?.toFixed(2)}</td>
                    <td width="20%" align="left"></td>
                </tr>
                <tr>
                    <td width="25%" align="left"></td>
                    <td width="10%" align="right"></td>
                    <td width="25%" align="left">Other Allowance</td>
                    <td width="20%" align="left">{parseFloat(fs?.transportation_notice_period)?.toFixed(2)}</td>
                    <td width="20%" align="left"></td>
                </tr>
                <tr>
                    <td width="25%" align="left"></td>
                    <td width="10%" align="right"></td>
                    <td width="25%" align="left">Housing Allowance</td>
                    <td width="20%" align="left">{parseFloat(fs?.housing_notice_period)?.toFixed(2)}</td>
                    <td width="20%" align="left">{(parseFloat(fs?.salary_notice_period) + parseFloat(fs?.transportation_notice_period) + parseFloat(fs?.housing_notice_period))?.toFixed(2)}</td>
                </tr>
            </table>
            <br /><br />
            <table width="100%" >
                <tr>
                    <td width="25%" align="left">3. Leave Salary...</td>
                    <td width="10%" align="right"></td>
                    <td width="25%" align="left">Due Days</td>
                    <td width="20%" align="left">{parseFloat(fs?.leave_days)?.toFixed(2)}</td>
                    <td width="20%" align="left">{parseFloat(fs?.leave_amount)?.toFixed(2)}</td>
                </tr>
            </table>
            <br /><br />
            <table width="100%" >
                <tr>
                    <td width="25%" align="left">4. Indemnity...</td>
                    <td width="10%" align="right"></td>
                    <td width="25%" align="left">Due Days</td>
                    <td width="20%" align="left">{parseFloat(fs?.indemnity_days)?.toFixed(2)}</td>
                    <td width="20%" align="left">{parseFloat(fs?.indemnity_amount)?.toFixed(2)}</td>
                </tr>
            </table>
            <br /><br />
            <table width="100%" >
                <tr>
                    <td width="25%" align="left">5. Others...</td>
                    <td width="10%" align="right"></td>
                    <td width="25%" align="left">Bonus/Adj.</td>
                    <td width="20%" align="left"><span color={"red"}>{parseFloat(fs?.adjustment)?.toFixed(2)}</span></td>
                    <td width="20%" align="left"></td>
                </tr>
                <tr>
                    <td width="25%" align="left"></td>
                    <td width="10%" align="right"></td>
                    <td width="25%" align="left">Ticket Fare</td>
                    <td width="20%" align="left"><span color={"red"}>{parseFloat(fs?.ticket_amount)?.toFixed(2)}</span></td>
                    <td width="20%" align="left"><span color={"red"}>{(parseFloat(fs?.adjustment) + parseFloat(fs?.ticket_amount))?.toFixed(2)}</span></td>
                </tr>
            </table>
            <br /><br />

            <table width="100%">
                {deduction_itme?.map(item => {
                    return item
                })}
            </table>
            <br /><br />
            <table width="100%" >
                <tr>
                    <td width="25%" align="left"></td>
                    <td width="10%" align="right"></td>
                    <td width="25%" align="left"></td>
                    <td width="20%" align="right"><strong>To Pay:</strong></td>
                    <td width="20%" align="left"><strong>{parseFloat(fs?.net_to_pay)?.toFixed(2)}</strong></td>
                </tr>
            </table>
            <hr />

            <table width="100%" >
                <tr>
                    <td width="18%" align="left"><strong>{fs?.currency_name}</strong></td>
                    <td width="82%" align="left"><strong>{fs?.net_to_pay ? converter.toWords(parseFloat(fs?.net_to_pay)?.toFixed(2)) : ''}</strong></td>
                </tr>
            </table>
            <hr />
            <table width="100%" >

                <tr>
                    <td width="15%" align="left"><strong>Bank:</strong></td>
                    <td width="35%" align="left">{fs?.bankname}</td>
                    <td width="15%" align="left"><strong>Account:</strong></td>
                    <td width="35%" align="left">{fs?.iban_number}</td>
                </tr>
                <tr>
                    <td width="15%" align="left"><strong>Swift Code:</strong></td>
                    <td width="35%" align="left">{fs?.swift_code}</td>
                    <td width="15%" align="left"><strong>Remarks:</strong></td>
                    <td width="35%" align="left">{fs?.remark}</td>
                </tr>
            </table>
            <br /><br />
            <table width="100%" >
                <tr>
                    <td width="25%" align="left" height='40px'><hr /><strong>HR Dept.</strong></td>
                    <td width="25%" align="left"></td>
                    <td width="25%" align="left"></td>
                    <td width="25%" align="left" height='40px'><hr /><strong>Accounts Dept.</strong></td>
                </tr>
            </table>
            <hr />
            Declaration: I the undersigned, herby declare and confirm that i have received from<br />
            In full and all wages and salaries and all compensations and indemnities due to me for my entire period of service with the<br />
            said company
            <span>{fs?.date_group_start_date}</span>
            up to
            <span>{fs?.end_date}</span>
            <table width="100%" >
                <tr>
                    <td width="25%" align="left"><hr /><strong>Witness Name & Signature</strong></td>
                    <td width="25%" align="left"></td>
                    <td width="25%" align="left"></td>
                    <td width="25%" align="left"><hr /><strong>Beneficiary Signature</strong></td>
                </tr>
            </table>
            <br /><br />
            <table width="100%" >
                <tr>
                    <td align="left" width="50%"><strong>Waiver and Release Deed</strong></td>
                    <td align="right" width="50%"><strong>{current_date}</strong></td></tr>
            </table>
            <hr />
            {fs_letter}
            <br /><br />
            <table width="100%" >
                <tr>
                    <td width="25%" align="left"><hr /><strong>Name and Signature</strong></td>
                    <td width="25%" align="left"></td>
                    <td width="25%" align="left"></td>
                    <td width="25%" align="left"><hr /><strong>Witness Name and Signature</strong></td>
                </tr>
            </table>

        </div>
        return html_st
    }

    const generate_reportKuwait = async (fs) => {
        if (!fs?.eos_public_holiday) {
            fs.eos_public_holiday = '0'
        }
        if (!fs?.eos_extra_hours) {
            fs.eos_extra_hours = '0'
        }
        fs.remark = fs.remark.trim()
        if (fs.remark === 'False' || fs.remark === 'false') {
            fs.remark = ''
        }
        let deduction_itme: any = []
        await fs?.deductions?.map((deduction, index) => {
            deduction_itme[index] = <tr>
                <td width="60%" align="left">{deduction.element_name}</td>
                <td width="40%" align="left">{parseFloat(deduction?.amount)?.toFixed(2)}</td>
            </tr>
        })
        if(fs.adjustment && parseFloat('' + fs.adjustment) < 0){
            deduction_itme.push(<tr>
                <td width="60%" align="left">Other Deductions</td>
                <td width="40%" align="left">{parseFloat('' + fs.adjustment)?.toFixed(2)}</td>
            </tr>)
        }
        return (
            <div>
                <table width="100%">
                    <tr>
                        <td align="center"><h2><u>Final Settlement</u></h2></td>
                    </tr>
                </table>
                <table width="100%" className="box-container-boarder" style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                    <tr style={{ border: '1px soild #000' }} >
                        <td width="50%" align="left"><strong>Settlement No:</strong>&nbsp;&nbsp;&nbsp;{fs?.serial_number}</td>
                        <td width="50%" align="right"><strong>Date:</strong>&nbsp;&nbsp;&nbsp;{moment(moment.now()).format(AZKHelper.REPORT_DATE_FORMAT)}</td>
                    </tr>
                    <tr className="box-container-boarder" style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                        <td className="box-container-boarder" style={{ border: '1px solid black', borderCollapse: 'collapse' }} width="50%">
                            <table width="100%" >
                                <tr>
                                    <td width="50%"><b>Employee ID - Name</b></td>
                                    <td width="50%">{fs?.azadea_id} - {employeeName}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>Job Title</b></td>
                                    <td width="50%">{fs?.job_id.name}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>Company</b></td>
                                    <td width="50%">{fs?.az_company_id.name}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>Shop/Dept Name</b></td>
                                    <td width="50%">{fs?.branch_id.name}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>Ending Nature</b></td>
                                    <td width="50%">{fs?.end_nature}</td>
                                </tr>
                            </table>
                        </td>
                        <td width="50%" className="box-container-boarder" style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                            <table width="100%">
                                <tr>
                                    <td width="50%"><b>Joining Date :</b></td>
                                    <td width="50%">{moment(fs?.joining_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>Ending Date :</b></td>
                                    <td width="50%">{moment(fs?.end_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>Actual Last working Date</b></td>
                                    <td width="50%">{moment(fs?.actual_termination_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}</td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>Non Service Days</b></td>
                                    <td width="50%"><span>{parseFloat(fs?.indemnity_year).toFixed(2)}</span></td>
                                </tr>
                                <tr>
                                    <td width="50%"><b>Indemnity Days</b></td>
                                    <td width="50%"><span>{parseFloat(fs?.indemnity_days).toFixed(2)}</span></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr >
                        <td width="50%" className="box-container-boarder" style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                            <table width="100%">
                                <tr>
                                    <td width="25%"><strong><u>Description</u></strong></td>
                                    <td width="25%"><strong><u>Entitlements</u></strong></td>
                                    <td width="25%"><strong><u>No of Days</u></strong></td>
                                    <td width="25%"><strong><u>Payable Amount</u></strong></td>
                                </tr>
                                <tr>
                                    <td width="25%">Commission</td>
                                    <td width="25%"></td>
                                    <td width="25%"></td>
                                    <td width="25%">{parseFloat(fs?.commission_settlement).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td width="25%"></td>
                                    <td width="25%"></td>
                                    <td width="25%"></td>
                                    <td width="25%"></td>
                                </tr>
                                <tr>
                                    <td width="25%">Leave Salary</td>
                                    <td width="25%"></td>
                                    <td width="25%">{parseFloat(fs?.leave_days).toFixed(2)}</td>
                                    <td width="25%">{parseFloat(fs?.leave_amount).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td width="25%"></td>
                                    <td width="25%"></td>
                                    <td width="25%"></td>
                                    <td width="25%"></td>
                                </tr>
                                <tr>
                                    <td width="25%">Indemnity</td>
                                    <td width="25%"></td>
                                    {/* {fs?.indemnity_days && <td width="25%">{parseFloat(fs?.indemnity_days).toFixed(2)}</td>} */}
                                    <td width="25%">{parseFloat(fs?.indemnity_days).toFixed(2)}</td>
                                    <td width="25%">{parseFloat(fs?.indemnity_amount).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td width="25%">Notice Period</td>
                                    <td width="25%"></td>
                                    <td width="25%"></td>
                                    <td width="25%">{parseFloat(fs?.salary_notice_period).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td width="25%">Extra Hours1</td>
                                    <td width="25%"></td>
                                    <td width="25%"></td>
                                    <td width="25%">{parseFloat(fs?.eos_extra_hours)?.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td width="25%">Other Additions</td>
                                    <td width="25%"></td>
                                    <td width="25%"></td>
                                    {fs.adjustment && fs.adjustment > 0 && <>
                                        <td width="25%">{(fs.adjustment + parseFloat(fs?.other_addition))?.toFixed(2)}</td>
                                    </>}
                                    {!fs.adjustment || fs.adjustment <= 0 && <>
                                        <td width="25%">{parseFloat(fs?.other_addition)?.toFixed(2)}</td>
                                    </>}
                                </tr>
                                <tr>
                                    <td width="25%">Ticket</td>
                                    <td width="25%"></td>
                                    <td width="25%"></td>
                                    <td width="25%">{parseFloat(fs?.ticket_amount)?.toFixed(2)}</td>
                                </tr>
                            </table>
                        </td>
                        <td width="50%" className="box-container-boarder" style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                            <table width="100%">
                                <tr>
                                    <td width="60%"><b><u>Description</u></b></td>
                                    <td width="40%"><b><u>Deductable Amt</u></b></td>
                                </tr>
                                {deduction_itme?.map(item => {
                                    return item
                                })}
                            </table>
                        </td>
                    </tr>
                    <tr className="box-container-boarder" style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                        <td width="50%">
                            <br />
                            <table width="100%">
                                <tr><td width="75%"><b>Total Payable AMT</b></td>
                                    <td width="25%">
                                        { (parseFloat('' + fs.adjustment) + parseFloat(fs.leave_amount) + parseFloat(fs.indemnity_amount) + parseFloat(fs.salary_notice_period) + parseFloat(fs.eos_extra_hours) + parseFloat(fs.eos_public_holiday) + parseFloat(fs.other_addition) + parseFloat(fs.ticket_amount) + parseFloat(fs?.commission_settlement)).toFixed(2)}
                                    </td></tr>
                            </table>
                        </td>
                        <td width="50%">
                            <br />
                            <table width="100%">
                                <tr><td width="60%"><b>Total Deductable AMT</b></td>
                                    <td width="40%">
                                        {fs?.deductions ? parseFloat(fs?.deductions.reduce((accumulator, item) => accumulator + item.amount, 0)).toFixed(2) : ''}
                                    </td></tr>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td><p>Total Amount: {fs?.net_to_pay ? converter.toWords(parseFloat(fs?.net_to_pay)?.toFixed(2)) : ''}</p></td>
                        <td><p>{parseFloat(fs?.net_to_pay)?.toFixed(2)}</p></td>
                    </tr>
                </table>

                <table width="100%" >
                    <br />
                    <tr>
                        <td width="15%" align="left"><strong>Bank:</strong></td>
                        <td width="35%" align="left">{fs?.bankname}</td>
                        <td width="15%" align="left"><strong>Account:</strong></td>
                        <td width="35%" align="left">{fs?.iban_number}</td>
                    </tr>
                    <tr>
                        <td width="15%" align="left"><strong>Remarks:</strong></td>
                        <td width="35%" align="left">{fs?.remark}</td>
                        <td width="15%" align="left"><strong>Swift Code:</strong></td>
                        <td width="35%" align="left">{fs?.swift_code}</td>
                    </tr>
                </table><br /><br />
                <table width="100%">
                    <tr>
                        <td width="25%" align="left"><strong>HR Dept.</strong><br /><br /><br /></td>
                        <td width="25%" align="left"></td>
                        <td width="25%" align="left"></td>
                        <td width="25%" align="left"><strong>Accounts Dept.</strong></td>
                    </tr>
                </table><br /><br />
                <p>
                    Declaration: I confirm that I have received the above total amount in full and final settlement of all the dues in full and all wages and salaries and all compensations and indemnities due to me for my entire period of service with the said company from {moment(fs?.date_group_start_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)} up to {moment(fs?.end_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}
                </p>
                <br /><br />
                <table width="100%">
                    <tr>
                        <td width="25%" align="left"></td>
                        <td width="25%" align="left"></td>
                        <td width="25%" align="left"></td>
                        <td width="25%" align="left"><hr /><strong>Beneficiary Signature</strong></td>

                    </tr>
                </table>
            </div>
        )
    }


    const getTargetElement = () => document.getElementById('content-id');


    const closeModal = () => {
        setRegenerateRequested(false)
    }

    return (
        <>
            <Row className="m-0 my-2 fs-form">
                <Col md='12' lg={id ? '9' : '9'} xl={id ? '9' : '9'}>
                    <Formik
                        innerRef={formRef}
                        validationSchema={editTransactionSchema}
                        onSubmit={onSubmitHandler}
                        initialValues={initialTransaction}
                        validateeOnChange={true}
                        validateOnMount={true}
                        initialErrors={''}>
                        {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
                            return (
                                <>
                                    {allowedButttons && allowedButttons.length > 0 && id &&
                                        <AzkActionButtons
                                            actionUrl={`${API_URL}/${MODEL_NAME}/${parseInt(id)}/`}
                                            thenNavigate="/transactions/"
                                            divStyle="card py-2 mb-3"
                                            buttons={allowedButttons} />}
                                    {/* {canApprove? { title: "Approve", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.APPROVED' }), method: "validate_tier", confirmMsg: "Approve Transaction, Confirm?", cssClass: "btn btn-light btn-light-success", icon: "fa-thumbs-up" }:()},
                                                { title: "Review", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.REVIEW' }), method: "restart_validation", confirmMsg: "Send Transaction back to draft, Confirm?", cssClass: "btn btn-light btn-light-warning", icon: "fa-refresh" },
                                                { title: "Decline", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.DECLINE' }), method: "reject_tier", confirmMsg: "Decline Transaction, Confirm?", cssClass: "btn btn-light btn-light-danger", icon: "fa-times" }, */}
                                    <Form onSubmit={handleSubmit} noValidate>
                                        <input
                                            accept={AZKHelper.ALLOWED_DOC_TYPE}
                                            disabled={transactionClosed}
                                            type='file'
                                            id='file'
                                            className="d-none"
                                            onChange={handleFileChange} />
                                        {employee && (
                                            <> {canApprove}

                                                <Row className="m-0 mb-3 card">
                                                    <div
                                                        className='card-header cursor-pointer px-3 employee_details-card-header'
                                                        role='button'
                                                        data-bs-toggle='collapse'
                                                        data-bs-target='#kt_az_employee_details'
                                                        aria-expanded='true'
                                                        aria-controls='kt_az_employee_details'
                                                    >
                                                        <div className='row card-title m-0  w-100 '>
                                                            <div className='d-flex justify-content-between'>
                                                                <h4 className='mb-2 mt-3'>
                                                                    <i className="fa fa-user" aria-hidden="true"></i>
                                                                    Employee Details</h4>
                                                                <i className="rounded btn btn-sm btn-icon btn-active-light-primary btn-light-primary fa-solid fa-chevron-up rounded"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Row id='kt_az_employee_details' className='m-0 collapse show pt-4 pb-2 rounded'>
                                                        <Row className='m-0'>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2 d-flex'>
                                                                    <Form.Label className='pt-1 p-0 col-12 col-lg-5'>Azadea Id/ Name
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        type="text"
                                                                        aria-label="Disabled input example"
                                                                        disabled
                                                                        readOnly
                                                                        defaultValue={employee?.azadea_id}
                                                                        name='azadea_id'
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2 d-flex'>
                                                                    <Form.Label className='pt-1 p-0 col-12 col-lg-5'>Name
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        type="text"
                                                                        aria-label="Disabled input example"
                                                                        disabled
                                                                        readOnly
                                                                        defaultValue={employee?.name}
                                                                        name='name'
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2 d-flex'>
                                                                    <Form.Label className='pt-1 p-0 col-12 col-lg-5'>Title
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        type="text"
                                                                        aria-label="Disabled input example"
                                                                        disabled
                                                                        readOnly
                                                                        defaultValue={fsObject?.job_id?.name}
                                                                        name='job_id'
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2 d-flex'>
                                                                    <Form.Label className='pt-1 p-0 col-12 col-lg-5'>Company
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        type="text"
                                                                        aria-label="Disabled input example"
                                                                        disabled
                                                                        readOnly
                                                                        defaultValue={fsObject?.az_company_id?.name}
                                                                        name='az_company_id'
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2 d-flex'>
                                                                    <Form.Label className='pt-1 p-0 col-12 col-lg-5'>Branch
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        type="text"
                                                                        aria-label="Disabled input example"
                                                                        disabled
                                                                        readOnly
                                                                        defaultValue={fsObject?.branch_id?.name}
                                                                        name='branch_id'
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2 d-flex'>
                                                                    <Form.Label className='pt-1 p-0 col-12 col-lg-5'>Ending Nature
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        type="text"
                                                                        aria-label="Disabled input example"
                                                                        disabled
                                                                        readOnly
                                                                        defaultValue={values.end_nature}
                                                                        name='end_nature'
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row className='m-0'>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2 d-flex'>
                                                                    <Form.Label className='pt-1 p-0 col-12 col-lg-5'>Joining Date
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        type="text"
                                                                        aria-label="Disabled input example"
                                                                        disabled
                                                                        readOnly
                                                                        defaultValue={moment(values.joining_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}
                                                                        name='joining_date'
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2 d-flex'>
                                                                    <Form.Label className='pt-1 p-0 col-12 col-lg-5'>Ending Date
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        type="text"
                                                                        aria-label="Disabled input example"
                                                                        disabled
                                                                        readOnly
                                                                        defaultValue={moment(values.end_date).format(AZKHelper.FRONTEND_TABLE_DATE_FORMAT)}
                                                                        name='end_date'
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2 d-flex'>
                                                                    <Form.Label className='pt-1 p-0 col-12 col-lg-5'>Basic Salary
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        type="text"
                                                                        aria-label="Disabled input example"
                                                                        disabled
                                                                        readOnly
                                                                        defaultValue={values?.basic_salary}
                                                                        name='basic_salary'
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2 d-flex'>
                                                                    <Form.Label className='pt-1 p-0 col-12 col-lg-5'>Housing Allowance
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        type="text"
                                                                        aria-label="Disabled input example"
                                                                        disabled
                                                                        readOnly
                                                                        defaultValue={values.housing_all}
                                                                        name='housing_all'
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2 d-flex'>
                                                                    <Form.Label className='pt-1 p-0 col-12 col-lg-5'>Other Allowance
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        type="text"
                                                                        aria-label="Disabled input example"
                                                                        disabled
                                                                        readOnly
                                                                        defaultValue={values.trans_all}
                                                                        name='trans_all'
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Row>
                                                </Row>
                                                <Row className="m-0 card">
                                                    <h4 className="py-2 px-6 m-0 card-header custom-card-header justify-content-start">
                                                        <i className="fa fa-info-circle" aria-hidden="true"></i>Final Settlement Details</h4>
                                                    <fieldset disabled={!canEdit || readOnly}>
                                                        <Row className="m-0 my-2">
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label >
                                                                        Salary Days
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        disabled={true}
                                                                        value={(parseFloat(values?.salary_days) + parseFloat(values?.housing_days) + parseFloat(values?.transportation_days) + parseFloat(values?.commission))?.toFixed(2)}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label >
                                                                        Notice Period
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        disabled={true}
                                                                        value={(parseFloat(values?.salary_notice_period) + parseFloat(values?.transportation_notice_period) + parseFloat(values?.housing_notice_period))?.toFixed(2)}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label>
                                                                        Leave Salary
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        disabled={true}
                                                                        value={values?.leave_amount ? parseFloat(values?.leave_amount)?.toFixed(2) : 0.00}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label>
                                                                        Indemnity
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        disabled={true}
                                                                        value={values?.indemnity_amount ? parseFloat(values?.indemnity_amount)?.toFixed(2) : 0.00}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label>
                                                                        Adjustment
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        disabled={true}
                                                                        value={values?.adjustment ? values?.adjustment?.toFixed(2) : 0.00}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label>
                                                                        Other Addition
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        disabled={true}
                                                                        min='0'
                                                                        value={values.other_addition_amount ? parseFloat(values.other_addition_amount).toFixed(2) : 0.00}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label>
                                                                        Other Deduction
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        max='0'
                                                                        disabled={true}
                                                                        value={values?.other_deduction_amount ? parseFloat(values?.other_deduction_amount)?.toFixed(2) : 0.00}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label>
                                                                        Ticket Amount
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        disabled={true}
                                                                        value={values?.ticket_amount ? parseFloat(values?.ticket_amount)?.toFixed(2) : 0.00}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label>
                                                                        Net to pay
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        disabled={true}
                                                                        value={values?.net_to_pay ? parseFloat(values?.net_to_pay)?.toFixed(2) : 0.00}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </fieldset>
                                                </Row>
                                                <Row className="m-0 card mt-3">
                                                    <h4 className="py-2 px-6 m-0 card-header custom-card-header justify-content-start">
                                                        <i className="fa fa-bank" aria-hidden="true"></i>
                                                        Bank Details
                                                    </h4>
                                                    <fieldset disabled={!canEdit || readOnly}>
                                                        <Row className="m-0 my-2">
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label >
                                                                        Bank
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        disabled={true}
                                                                        value={values.bankname}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label >
                                                                        Account
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        disabled={true}
                                                                        value={values.iban_number}
                                                                    />
                                                                </Form.Group>

                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label >
                                                                        Swift Code
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        disabled={true}
                                                                        value={values.swift_code}
                                                                    />
                                                                </Form.Group>

                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label >
                                                                        Remarks
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        disabled={true}
                                                                        value={values.remark}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row className="m-0 my-2">
                                                        </Row>
                                                    </fieldset>
                                                </Row>
                                            </>)}
                                    </Form>
                                </>
                            )
                        }}
                    </Formik>
                </Col>
                <Col md='12' lg='3' xl='3'>
                    {id && transactionSteps && (<AZKTimeLine transactionId={parseInt(id)} modelName={MODEL_NAME}
                        current={transactionState} currentName={transactionStateName} steps={transactionSteps} />
                    )}
                    {employee && refreshChatter && id && <AzkChatter transactionClosed={transactionClosed} isDrawer={true} modelName={MODEL_NAME} itemID={parseInt(id)} hitSend={hitSend} sendMessagesCallback={handleResultOfsubmit} />}
                </Col>
            </Row>
            {regenerateRequested && clearanceTransactionId && <FinalSettlementParametersModal transactionId={clearanceTransactionId} closeModal={function (): {} {
                setRegenerateRequested(false)
                return closeModal
            }} />}
        </>
    )
}

export default Transactionform;

