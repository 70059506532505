/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import {
  KTIcon, Response,
} from '../../../helpers'
import { Col, Form, Row } from 'react-bootstrap'
import { AUTH_LOCAL_STORAGE_KEY, AuthModel } from 'app/modules/auth'
import { API_URL } from '_azkatech/azkHelper'
import axios, { AxiosResponse } from 'axios'
import { AZKHelper } from 'app/modules/components/azkHelper'
import { AsyncPaginate } from 'react-select-async-paginate'
import CryptoJS from 'crypto-js';

type Props = {
  pinCode?: any
  closeModal: () => {}
}

const HeadeRequestAPPPinMenu: FC<Props> = ({ closeModal, pinCode = undefined }) => {
  const [selectedOption, setSelectedOption] = useState<any>();
  const [requestPinMethod, setRequestPinMethod] = useState<any>();
  const [pin_code, setPin_code] = useState<any>();

  const Swal = require('sweetalert2')
  const errorNotAllowed = async (message?: string) => {
    Swal.fire({
      position: "center",
      // title: 'Error!',
      text: message ? message : 'You are not allowed To request new pin code!',
      icon: "error",
      showConfirmButton: false,
      timer: AZKHelper.SWAL_TOAST_TIMER,
      // toast: true,
      iconColor: 'white',
      customClass: {
        popup: 'colored-toast'
      },
      timerProgressBar: true
    })
  }

  const requestNewPin = async () => { // sms email whatsapp
    if (requestPinMethod) {
      document.body.classList.add('page-loading')
      let lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
      if (lsValue) {
        const auth: AuthModel = JSON.parse(lsValue) as AuthModel
        if (auth && auth.uid) {
          await axios.put(`${API_URL}/res.users/${auth.uid}/request_pin_code?uid=${auth.uid}&method=${requestPinMethod}`)
            .then((response: AxiosResponse<Response<{ pin_code_send?: boolean, message?: string }>>) => response.data)
            .then((response: Response<{ pin_code_send?: boolean, message?: string  }>) => response)
            .then((response: any) => {
              response = JSON.parse(response)
              if (response['pin_code_send']) {
                Swal.fire({
                  position: "center",
                  // title: 'Success!',
                  text: 'New Pin Code had been requested!',
                  icon: "success",
                  showConfirmButton: false,
                  timer: AZKHelper.SWAL_TOAST_TIMER,
                  // toast: true,
                  iconColor: 'white',
                  customClass: {
                    popup: 'colored-toast'
                  },
                  timerProgressBar: true
                })
              } else {
                let message='Feaild to generate New Pin Code!';
                if (response['message']){
                  message = response['message']
                }
                Swal.fire({
                  position: "center",
                  // title: 'Failed!',
                  text: message,
                  icon: "error",
                  showConfirmButton: false,
                  timer: AZKHelper.SWAL_TOAST_TIMER,
                  // toast: true,
                  iconColor: 'white',
                  customClass: {
                    popup: 'colored-toast'
                  },
                  timerProgressBar: true
                })
              }
              document.body.classList.remove('page-loading')
            })
        } else {
          document.body.classList.remove('page-loading')
          errorNotAllowed()
        }
      } else {
        document.body.classList.remove('page-loading')
        errorNotAllowed()
      }
    } else {
      Swal.fire({
        position: "center",
        // title: 'Error!',
        text: 'Please fill the requset pin method!',
        icon: "error",
        showConfirmButton: false,
        timer: AZKHelper.SWAL_TOAST_TIMER,
        // toast: true,
        iconColor: 'white',
        customClass: {
          popup: 'colored-toast'
        },
        timerProgressBar: true
      })
    }
  }

  const savePinCode = () => {
    if (pin_code) {
      let jsonObj = {}
      jsonObj['pin_code'] = CryptoJS.AES.encrypt(pin_code, AZKHelper.PIN_CODE_KEY).toString()
      const lsValue = JSON.stringify(jsonObj)
      localStorage.setItem(AZKHelper.PIN_CODE_LOCAL_STORAGE_KEY, lsValue)
      closeModal()
      Swal.fire({
        position: "center",
        // title: 'Success!',
        text: 'Your new pin code had been saved!',
        icon: "success",
        showConfirmButton: false,
        timer: AZKHelper.SWAL_TOAST_TIMER,
        // toast: true,
        iconColor: 'white',
        customClass: {
          popup: 'colored-toast'
        },
        timerProgressBar: true
      })

    } else {
      Swal.fire({
        position: "center",
        // title: 'Error!',
        text: 'Please enter valid pin code!',
        icon: "error",
        showConfirmButton: false,
        timer: AZKHelper.SWAL_TOAST_TIMER,
        // toast: true,
        iconColor: 'white',
        customClass: {
          popup: 'colored-toast'
        },
        timerProgressBar: true
      })
    }
  }

  const loadOptions = async (search, loadedOptions) => {
    const options = [
      { value: 'sms', label: 'SMS' },
      { value: 'email', label: 'Email' },
      { value: 'whatsapp', label: 'WhatsApp' },
    ];

    const filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(search.toLowerCase())
    );

    return {
      options: filteredOptions,
      hasMore: false,
    };
  };

  useEffect(() => {
    async function fetchInitialValue() {
      const options = await loadOptions('', []);
      setSelectedOption(options['options'][0]);
      setRequestPinMethod(options['options'][0].value)
    }
    fetchInitialValue();
  }, []);

  const handleOnChange = (selectedOption) => {
    setSelectedOption(selectedOption)
    setRequestPinMethod(selectedOption.value)
  };

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered px-3'>
          {/* begin::Modal content */}
          <div className='modal-content'>

            <div className='text-center py-3'>
              {/* begin::Modal title */}
              <h4 className='m-0'>Enter PIN:</h4>
              {/* end::Modal title */}

              {/* begin::Close */}
              {/* <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => closeModal()}
                style={{ cursor: 'pointer' }}
              >
                <i className="fa fa-times fs-1 btn btn-sm" aria-hidden="true"></i>
              </div> */}
              {/* end::Close */}
            </div>
            {/* begin::Modal body */}
            <div className='' >
              <Row className="m-0 py-3">
                <Col md='12' lg='12' xl='12'> {pinCode}
                  <Form.Control
                    autoFocus={true}
                    className='p-1 rounded-0'
                    type="text"
                    name='pin-code-input'
                    defaultValue={pinCode}
                    onChange={(val) => {
                      pinCode = val.target.value
                      setPin_code(pinCode)
                    }}
                  />
                </Col>
              </Row>
            </div>
            {/* end::Modal body */}
            <div className='my-2 row m-0'>
              <div className='col-12 d-flex jsutify-content-between py-3'>
                {/* {pin_code && */}
                <button id='button' type='button' className='btn btn-dark rounded-0 btn-sm col-12'
                  onClick={savePinCode}>
                  {/* <i className="fas fa-check fs-2"></i> */}
                  <span className='indicator-label'>Save</span>
                </button>
                {/* } */}
              </div>
              <div className='col-12 d-flex jsutify-content-between p-3 m-0'>
                {!pin_code && <>
                  <AsyncPaginate className='rounded-0 col-6'
                    placeholder="Select an option"
                    loadOptions={loadOptions}
                    onChange={handleOnChange}
                    defaultValue={selectedOption}
                    value={selectedOption}
                  />
                  <button type='button' className=' col-6 btn btn rounded-0 btn-sm request-pin-btn' onClick={requestNewPin}>
                    {/* <i className="fas fa-refresh"></i> */}
                    <u>
                      <span className='indicator-label'>Request Pin</span>
                    </u>
                  </button></>
                }
              </div>
            </div>
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export { HeadeRequestAPPPinMenu }


