import AzkSelect from "_azkatech/azkSelect";
import { EmployeeDetail, EmpoyeeModel } from "app/modules/components/employeeDetail/employeeDetail";
import axios, { ResponseType } from "axios";
import * as Yup from 'yup'
import { TravelRequest, TravelRequestLine, initialTransfaction } from "./model";
import { FC, useEffect, useRef, useState } from "react";
import { ErrorMessage, FieldArray, Formik, FormikProps, FormikValues } from 'formik'
import { CreateTravelRequest, UpdateTravelRequest, GetTravelRequestById, MODEL_NAME, DeleteTravelRequest } from "./_requests";
import "react-datepicker/dist/react-datepicker.css";
import { AzkStatusRibbon } from "_azkatech/azkStatusRibbon";
import AzkActionButtons from "_azkatech/azkActionButtons";
import { Form, Row, Col } from "react-bootstrap";
import { useIntl } from "react-intl";
import { ID, isNotEmpty, KTIcon } from "_metronic/helpers";
import { useNavigate } from "react-router";
import { API_URL } from "_azkatech/azkHelper";
import { AZKHelper } from "app/modules/components/azkHelper";
import { AzkChatter } from "_azkatech";
import { checkTrnsactionTypeAccessId } from "app/utilities/transactionTyperequests";
import { useParams } from "react-router-dom";
import { GetEmployeeByIdNoSalary, GetEmployeeByUserId } from "app/modules/components/employeeDetail/_requests";
import { AUTH_LOCAL_STORAGE_KEY, AuthModel } from "app/modules/auth";
import { AsyncPaginate } from "react-select-async-paginate";
import moment from "moment";

type Props = {
}
const editTransactionSchema = Yup.object().shape({
    employee_id: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value)
        .required('Employee is required'),
    passport_number: Yup.string().required('Passport number is required'),
    name_in_passport: Yup.string().required('Name is required'),
    passport_expiry: Yup.date().transform((value) => !moment(value).isValid() ? undefined : value).required('Passport expiry date is required')
        .min(new Date(), 'Date from must be after today'),
    line_ids: Yup.array().of(
        Yup.object().shape({
            departure_date: Yup.date().transform((value) => !moment(value).isValid() ? undefined : value).required('Departure date is required')
                .min(new Date(), 'Date from must be after today'),
            departure_city: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value).required('Departure city from is required'),
            arrival_city: Yup.number().transform((value) => Number.isNaN(value) ? undefined : value).required('Arrival city from is required'),
        })
    )
})

let is_submitting_form = false
let submit_validation_transaction = false
const TransactionViewform: FC<Props> = () => {
    const { id } = useParams();
    const Swal = require('sweetalert2')
    const intl = useIntl();
    const navigate = useNavigate()
    const formRef = useRef<FormikProps<TravelRequest>>(null);
    const [travelRequestTypeDomain, setTravelRequestTypeDomain] = useState('');
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const [readOnly, setReadOnly] = useState<boolean>(false);
    const [transactionClosed, setTransactionClosed] = useState<boolean>(false);
    const [canValidate, setCanValidate] = useState<boolean>(false);
    const [canDelete, setCanDelete] = useState<boolean>(false);
    const [employee, setEmployee] = useState<EmpoyeeModel>();
    const [transactionState, setTransactionState] = useState<string>('draft');
    const [transactionStateName, setTransactionStateName] = useState<string>('Draft');
    const [hitSend, setHitSend] = useState<boolean>(false);
    const [objectId, setObjectId] = useState<number>();


    const [mangerUser, setMangerUser] = useState<boolean>(false);
    const [shopUser, setShopUser] = useState<boolean>(true);
    const [employeeDomain, setEmployeeDomain] = useState<string>('');
    const checkUserType = () => {
        let lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
        if (lsValue) {
            const auth: AuthModel = JSON.parse(lsValue) as AuthModel
            if (auth && auth.uid) {
                GetEmployeeByUserId(auth.uid).then(response => {
                    if (response) {
                        let domain = ``
                        if (response.is_back_office_fr && response.is_back_office_fr !== null) setShopUser(!response?.is_back_office_fr)
                        if (response.is_manager_fr) setMangerUser(response?.is_manager_fr)
                        // if (response.is_manager_fr && response.is_back_office_fr) {
                        //     domain = domain.concat(`'|', ('id', '=', ${response.id})`)
                        // }
                        // if (!response.is_manager_fr && !response.is_back_office_fr) {
                        //     domain = domain.concat(`('id', '=', ${response.id})`)
                        // }
                        // if (!response.is_manager_fr && response.is_back_office_fr) {
                        //     domain = domain.concat(`('id', '=', ${response.id})`)
                        // }
                        if (response.allwoed_travel_request && response.allwoed_travel_request !== null) {
                            domain = domain.concat(`'|', ('id', '=', ${response.id}), ('id', 'in', [${response.allwoed_travel_request}])`)
                        } else {
                            domain = domain.concat(`('id', '=', ${response.id})`)
                        }
                        setEmployeeDomain(domain)
                    }

                })
            }
        }
    }

    const handleEmployeeChange = async function (employeeId, TravelRequestData): Promise<{}> {
        document.body.classList.add('page-loading')
        if (employeeId) {
            setEmployee(undefined)
            GetEmployeeByIdNoSalary(employeeId).then(response => {
                if (response) {
                    const tempEmployee = response
                    setEmployee(response)
                    setTravelRequestTypeDomain(tempEmployee.az_country_id ? `('country_id','=', ${tempEmployee.az_country_id?.id})` : '');
                    // handleJobChange(false)
                    formRef.current?.setValues({
                        employee_id: tempEmployee.id,
                        az_country_id: tempEmployee.az_country_id?.id,
                        az_company_id: tempEmployee.az_company_id?.id,
                        branch_id: tempEmployee.branch_id?.id,
                        brand_id: tempEmployee.brand_id?.id,
                        grade_id: tempEmployee.grade_id?.id,
                        section_id: tempEmployee.section_id?.id,
                        job_id: tempEmployee.job_id?.id,
                        // time_set: tempEmployee.time_set,
                        // ---------------------------------------------------
                        line_ids: []
                    })
                    if (TravelRequestData) {
                        pobulateFormData(TravelRequestData)
                    } else {
                    }
                    document.body.classList.remove('page-loading')
                } else {
                    document.body.classList.remove('page-loading')
                }
            });
        } else {
            document.body.classList.remove('page-loading')
            formRef.current?.resetForm();
            setEmployee(undefined)
            formRef.current?.setValues({
                employee_id: undefined,
                az_country_id: undefined,
                az_company_id: undefined,
                branch_id: undefined,
                brand_id: undefined,
                grade_id: undefined,
                section_id: undefined,
                job_id: undefined,
                time_set: undefined,
                line_ids: []
            })
        }
        return true
    }
    
    const pobulateFormData = async (data?: TravelRequest) => {
        if (data) {
            formRef.current?.setValues({
                id: data.id,
                employee_id: data.employee_id?.id,
                az_country_id: data.az_country_id?.id,
                az_company_id: data.az_company_id?.id,
                branch_id: data.branch_id?.id,
                brand_id: data.brand_id?.id,
                grade_id: data.grade_id?.id,
                section_id: data.section_id?.id,
                job_id: data.job_id?.id,
                time_set: data.time_set,
                azadea_employee_name: data.azadea_employee_name,
                name_in_passport: data?.name_in_passport,
                mobile_number: data?.mobile_number,
                date_of_birth: data?.date_of_birth,
                passport_number: data?.passport_number,
                passport_expiry: data?.passport_expiry,
                smoking: data?.smoking,
                has_credit_card: data?.has_credit_card,
                travel_insurance: data?.travel_insurance,
                additional_remarks: data?.additional_remarks,
                save_personal_data: data?.save_personal_data,
                line_ids: data?.line_ids
            })
        }
    }

    const checkPermission = async (action: string) => {
        const allowed = await checkTrnsactionTypeAccessId(MODEL_NAME, action)
        if (!allowed) {
            if (id) {
                const allowed_read = await checkTrnsactionTypeAccessId(MODEL_NAME, 'read')
                if (!allowed_read) {
                    navigate(`/self-service/travel-request/`, { state: {}, replace: true });
                    Swal.fire({
                        position: "top-right",
                        title: 'Access Error!',
                        text: 'You does not have access to perform read action!',
                        icon: "error",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                } else {
                    setReadOnly(false)
                }
            } else {
                navigate(`/self-service/travel-request/`, { state: {}, replace: true });
                Swal.fire({
                    position: "top-right",
                    title: 'Access Error!',
                    text: 'You does not have access to perform this action!',
                    icon: "error",
                    showConfirmButton: false,
                    timer: AZKHelper.SWAL_TOAST_TIMER,
                    toast: true,
                    iconColor: 'white',
                    customClass: {
                        popup: 'colored-toast'
                    },
                    timerProgressBar: true
                })
            }
        }
    }

    useEffect(() => {
        checkUserType()
        document.body.classList.add('page-loading')
        formRef.current?.resetForm()
        setObjectId(undefined)
        setCanValidate(false)
        handleEmployeeChange(undefined, undefined)
        if (id) {
            setObjectId(parseInt(id))
            checkPermission('write');
            document.body.classList.add('page-loading')
            GetTravelRequestById(parseInt(id)).then(((data: TravelRequest) => {
                handleEmployeeChange(data?.employee_id?.id, data)
                if (data?.x_state && data?.x_state_view) {
                    setTransactionState(data?.x_state)
                    setTransactionStateName(data?.x_state_view)
                }
                if (data?.can_write && data?.can_write !== null) {
                    setCanEdit(data?.can_review ? false : data?.can_write )
                }
                setTransactionClosed(data.validated || data.rejected ? true : false)
                setCanValidate(data?.can_review ? true : false)
                setCanDelete(data?.can_delete ? true : false)
            }))
        } else {
            checkPermission('create');
            formRef.current?.setFieldValue('employee_id', undefined)
            handleEmployeeChange(undefined, undefined)
            setCanEdit(true)
            document.body.classList.remove('page-loading')
        }
    }, [id])


    const cancel = () => {
        navigate(`/self-service/travel-request/`, { state: {}, replace: true });
    }

    const deleteTransaction = () => {
        Swal.fire({
            position: "center",
            title: 'Delete',
            text: 'Delete Transaction confirm?',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed && id) {
                DeleteTravelRequest(parseInt(id)).then(() => {
                    cancel()
                    Swal.fire({
                        position: "top-right",
                        title: 'Deleted!',
                        text: 'Your transaction had been deleted!',
                        icon: "warning",
                        showConfirmButton: false,
                        timer: AZKHelper.SWAL_TOAST_TIMER,
                        toast: true,
                        iconColor: 'white',
                        customClass: {
                            popup: 'colored-toast'
                        },
                        timerProgressBar: true
                    })
                })
            } else if (result.isDenied) {
                Swal.fire('Transaction was not deleted', '', 'info')
            }
        })
    }

    const submitTransaction = (transactionId: number) => {
        axios.put(`${API_URL}/${MODEL_NAME}/${transactionId}/request_validation`, '{}').then(data => {
            document.body.classList.remove('page-loading')
            navigate(`/self-service/travel-request/`, { state: {}, replace: true });
            Swal.fire({
                position: "top-right",
                title: 'Success!',
                text: 'Your transaction had been submited!',
                icon: "success",
                showConfirmButton: false,
                timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
        })
    }

    const handleResultOfsubmit = (transactionId: ID | undefined, isUpdate: boolean | false) => {
        document.body.classList.remove('page-loading')
        navigate(`/self-service/travel-request/`, { state: {}, replace: true })
        Swal.fire({
            position: "top-right",
            title: 'Success!',
            text: isUpdate ? 'Your transaction had been updated!' : 'Your transaction had been saved!',
            icon: "success",
            showConfirmButton: false,
            timer: AZKHelper.SUCCESS_SWAL_TOAST_TIMER,
            toast: true,
            iconColor: 'white',
            customClass: {
                popup: 'colored-toast'
            },
            timerProgressBar: true
        })
    }

    const onSubmitHandler = async (values: TravelRequest, actions: FormikValues) => {
        actions.setSubmitting(true);
        formRef.current?.validateForm()
        if (formRef.current?.isValid && is_submitting_form) {
            document.body.classList.add('page-loading')
            if (isNotEmpty(values.id)) {
                let elements_to_save: TravelRequestLine[] = []
                let elements: TravelRequestLine[] = formRef.current?.getFieldProps('line_ids').value
                if (!elements) elements = []
                // replace the element object
                await elements.map((element) => {
                    element.id = undefined
                    elements_to_save.push(element)
                });
                values['line_ids'] = elements_to_save
                await UpdateTravelRequest(values).then(data => {
                    if (id) {
                        if (submit_validation_transaction) {
                            axios.put(`${API_URL}/${MODEL_NAME}/${id}/request_validation`, '{}').then(data => {
                                handleResultOfsubmit(parseInt(id), true)
                            })
                        } else {
                            handleResultOfsubmit(parseInt(id), true)
                        }
                    }
                })
            } else {
                await CreateTravelRequest(values).then(data => {
                    setObjectId(data?.id)
                    if (submit_validation_transaction)
                        axios.put(`${API_URL}/${MODEL_NAME}/${data?.id}/request_validation`, '{}').then(data => {
                            setHitSend(true)
                        })
                })
            }
        } else {
            actions.setSubmitting(false)
            formRef.current?.validateForm().then(errors => {
                if (Object.keys(errors).length > 0) {
                    console.log(errors)
                    //   setStatus('error');
                }
            });
        }
    }

    const approveTransaction = () => {
        axios.put(`${API_URL}/${MODEL_NAME}/${id}/validate_tier`, '{}').then(data => {
            document.body.classList.remove('page-loading')
            Swal.fire({
                position: "top-right",
                title: 'Success!',
                text: intl.formatMessage({ id: 'TRANSACTION.APPROVED' }),
                icon: "success",
                showConfirmButton: false,
                timer: AZKHelper.SWAL_TOAST_TIMER,
                toast: true,
                iconColor: 'white',
                customClass: {
                    popup: 'colored-toast'
                },
                timerProgressBar: true
            })
            navigate('/self-service/travel-request/', { state: {}, replace: true });
        })
    }
    const addcomment = async () => {
        const comment = formRef?.current?.values.comment
        if (id && comment) {
            axios.put(`${API_URL}/${MODEL_NAME}/${parseInt(id)}/add_comment`, { 'comment': comment }).then(response => {
                approveTransaction()
            })
        } else {
            approveTransaction()
        }
    }
    
    const loadTypeOptions = async (search, loadedOptions) => {
        const options: any[] = []
        options.push({ value: 'support', label: 'Support' })
        options.push({ value: 'training', label: 'Training' })
        const filteredOptions = options.filter((option) =>
            option.label.toString().includes(search.toLowerCase())
        );
        return {
            options: filteredOptions,
            hasMore: false,
        };
    };

    return (
        <>
            <Row className="m-0 my-2">
                <Col md='12' lg={id ? '9' : '9'} xl={id ? '9' : '9'}>
                    <Formik
                        innerRef={formRef}
                        validationSchema={editTransactionSchema}
                        onSubmit={onSubmitHandler}
                        initialValues={initialTransfaction}
                        validateeOnChange={true}
                        validateOnMount={true}
                        initialErrors={''}
                    >
                        {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
                            // ,validateForm,isSubmitting, errors, touched, isValidating
                            return (
                                <>
                                    {/* {id && (<AzkStatusBar transactionId={id} modelName={MODEL_NAME}
                                        current={transactionState} />
                                    )} */}
                                    {canValidate && id &&
                                        <AzkActionButtons
                                            backLink="/self-service/travel-request/"
                                            actionUrl={`${API_URL}/${MODEL_NAME}/${id}/`}
                                            thenNavigate="/self-service/travel-request/"
                                            divStyle="card py-2 mb-3"
                                            buttons={[
                                                { title: "Approve", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.APPROVED' }), method: "validate_tier", confirmMsg: "Approve Transaction, Confirm?", cssClass: "btn btn-light btn-light-success", icon: "fa-thumbs-up", callback: addcomment },
                                                { title: "Review", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.REVIEW' }), method: "restart_validation", confirmMsg: "Send Transaction back to draft, Confirm?", cssClass: "btn btn-light btn-light-warning", icon: "fa-refresh" },
                                                { title: "Decline", alertSuccess: intl.formatMessage({ id: 'TRANSACTION.DECLINE' }), method: "reject_tier", confirmMsg: "Decline Transaction, Confirm?", cssClass: "btn btn-light btn-light-danger", icon: "fa-times" },
                                            ]} />}

                                    <Form onSubmit={handleSubmit} noValidate>
                                        <fieldset disabled={!canEdit || readOnly}>
                                            {!id && (
                                                <Row className="card m-0 py-2 mb-3">
                                                    <Col>
                                                        <Form.Group className='mb-2'>
                                                            <Form.Label>
                                                                <h4 className="px-3 m-0 custom-employee-header justify-content-start">
                                                                    <i className="fa fa-magnifying-glass" aria-hidden="true"></i>{intl.formatMessage({ id: 'TRANSACTION.EMPLOYEE' })}
                                                                </h4>
                                                            </Form.Label>
                                                            <AzkSelect
                                                                compID="azk_temp"
                                                                modelName="hr.employee"
                                                                valueField="id"
                                                                titleField="display_name_fr"
                                                                dataDomain={employeeDomain}
                                                                allowEmpty={true}
                                                                fieldName='employee_id'
                                                                onChange={(value) => {
                                                                    setFieldValue('employee_id', parseInt(value))
                                                                    handleEmployeeChange(value, undefined)
                                                                }}
                                                                defaultValue={values.employee_id}
                                                                disabled={!canEdit}
                                                                searchFields={['name', 'azadea_id', 'branch_id', 'job_id', 'work_email', 'first_name']} />
                                                            <div className='text-danger'>
                                                                <ErrorMessage name='employee_id' />
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            )}
                                        </fieldset>
                                        {employee && (
                                            <>
                                                <EmployeeDetail employee={employee} />
                                                <Row className="m-0 card">
                                                    <h4 className="py-2 px-6 m-0 card-header custom-card-header justify-content-start">
                                                        <i className="fa fa-info-circle" aria-hidden="true"></i>Travel Request Details</h4>
                                                    <fieldset disabled={!canEdit || readOnly}>
                                                        <Row className="m-0 my-2">
                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                    Name as in passport
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        type="text"
                                                                        name="name_in_passport"
                                                                        id="name_in_passport"
                                                                        onChange={(val) => {
                                                                            setFieldValue('name_in_passport', val.target.value)
                                                                        }}
                                                                        value={values.name_in_passport}
                                                                    />
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='name_in_passport' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className="required">
                                                                        Passport Number
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        className='p-1'
                                                                        name="passport_number"
                                                                        id="passport_number"
                                                                        onChange={(val) => {
                                                                            setFieldValue('passport_number', val.target.value)
                                                                        }}
                                                                        value={values.passport_number}
                                                                    />
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='passport_number' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className="required">
                                                                        Passport Expiry
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        type='date'
                                                                        name="passport_expiry"
                                                                        id="passport_expiry"
                                                                        onChange={(value) => {
                                                                            setFieldValue('passport_expiry', moment(value.target.value).format(AZKHelper.BACKEND_DATE_FORMAT))
                                                                        }}
                                                                        disabled={!canEdit}
                                                                        value={moment(values.passport_expiry).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                    />
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='passport_expiry' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label className='required'>
                                                                        Mobile Number
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        type="text"
                                                                        name="mobile_number"
                                                                        id="mobile_number"
                                                                        onChange={(val) => {
                                                                            setFieldValue('mobile_number', val.target.value)
                                                                        }}
                                                                        value={values.mobile_number}
                                                                    />
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='mobile_number' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12">
                                                                <Form.Group className='mb-2'>
                                                                    <Form.Label>
                                                                        Date Of Birth
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        className='p-1'
                                                                        type='date'
                                                                        name="date_of_birth"
                                                                        id="date_of_birth"
                                                                        onChange={(value) => {
                                                                            setFieldValue('date_of_birth', moment(value.target.value).format(AZKHelper.BACKEND_DATE_FORMAT))
                                                                        }}
                                                                        disabled={!canEdit}
                                                                        value={moment(values.date_of_birth).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                    />
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='date_of_birth' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>

                                                            <Col lg="4" md="4" sm="6" xs="12" className="d-flex">
                                                                <Form.Group className='mb-2 align-self-end'>
                                                                    <Form.Label>
                                                                    </Form.Label>
                                                                    <Row>
                                                                        <Col lg="12" md="6" sm="12" xs="12">
                                                                            <Form.Check
                                                                                label="Smoking Hotel Room"
                                                                                value="true"
                                                                                id={"smoking"}
                                                                                name={"smoking"}
                                                                                onChange={(value) => {
                                                                                    values.smoking = value.target.checked
                                                                                    setFieldValue('smoking', value.target.checked)
                                                                                }}
                                                                                defaultChecked={values.smoking}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='smoking' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12" className="d-flex">
                                                                <Form.Group className='mb-2 align-self-end'>
                                                                    <Form.Label>

                                                                    </Form.Label>
                                                                    <Row>
                                                                        <Col lg="12" md="12" sm="12" xs="12">
                                                                            <Form.Check
                                                                                label="Has Corporate credit card"
                                                                                id={"has_credit_card"}
                                                                                name={"has_credit_card"}
                                                                                onChange={(value) => {
                                                                                    values.has_credit_card = value.target.checked
                                                                                    setFieldValue('has_credit_card', value.target.checked)
                                                                                }}
                                                                                defaultChecked={values.has_credit_card}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='has_credit_card' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12" className="d-flex">
                                                                <Form.Group className='mb-2 align-self-end'>
                                                                    <Form.Label>
                                                                    </Form.Label>
                                                                    <Row>
                                                                        <Col lg="12" md="12" sm="12" xs="12">
                                                                            <Form.Check
                                                                                label="Has Yearly travel Insurance"
                                                                                id={"travel_insurance"}
                                                                                name={"travel_insurance"}
                                                                                onChange={(value) => {
                                                                                    values.travel_insurance = value.target.checked
                                                                                    setFieldValue('travel_insurance', value.target.checked)
                                                                                }}
                                                                                defaultChecked={values.travel_insurance}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='travel_insurance' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg="4" md="4" sm="6" xs="12" className="d-flex">
                                                                <Form.Group className='mb-2 align-self-end'>
                                                                    <Form.Label>
                                                                    </Form.Label>
                                                                    <Row>
                                                                        <Col lg="12" md="12" sm="12" xs="12">
                                                                            <Form.Check
                                                                                label="Save personal data"
                                                                                id={"save_personal_data"}
                                                                                name={"save_personal_data"}
                                                                                onChange={(value) => {
                                                                                    values.save_personal_data = value.target.checked
                                                                                    setFieldValue('save_personal_data', value.target.checked)
                                                                                }}
                                                                                defaultChecked={values.save_personal_data}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                    <div className='text-danger'>
                                                                        <ErrorMessage name='save_personal_data' />
                                                                    </div>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row className="m-0 my-2">
                                                            <div className=''> {/* table-responsive */}
                                                                <table
                                                                    id='kt_table_users'
                                                                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                                                                    <thead>
                                                                        <tr className='text-start text-muted fw-bold fs-8 text-uppercase gs-0'>
                                                                            <th>Departure Date</th>
                                                                            <th>Departure City</th>
                                                                            <th>Arrival City</th>
                                                                            <th>Need Hotel</th>
                                                                            <th>Need Visa</th>
                                                                            <th>Purpose</th>
                                                                            <th>Passport Visa Info</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='text-gray-600 fs-8'>
                                                                        <FieldArray name="line_ids">
                                                                            {({ push, remove }) => (
                                                                                <>
                                                                                    {values.line_ids.map((line, index) => (
                                                                                        <tr key={index}>
                                                                                            <td>
                                                                                                <Form.Control
                                                                                                    className='p-1'
                                                                                                    type="date"
                                                                                                    id={`line_ids[${index}].departure_date`}
                                                                                                    name={`line_ids[${index}].departure_date`}
                                                                                                    onChange={(value) => {
                                                                                                        line.departure_date = moment(value.target.value).format(AZKHelper.BACKEND_DATE_FORMAT)
                                                                                                        values.line_ids[index].departure_date = moment(value.target.value).format(AZKHelper.BACKEND_DATE_FORMAT)
                                                                                                        formRef?.current?.validateForm()
                                                                                                    }}
                                                                                                    // min={}
                                                                                                    defaultValue={moment(values.line_ids[index].departure_date).format(AZKHelper.FRONTEND_DATE_FORMAT)}
                                                                                                />
                                                                                                <div className='text-danger'>
                                                                                                    <ErrorMessage name={`line_ids[${index}].departure_date`} component="div" />
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <AzkSelect
                                                                                                    compID={"az_departure_city_select" + index}
                                                                                                    modelName="res.city"
                                                                                                    valueField="id"
                                                                                                    titleField="name"
                                                                                                    dataDomain=''
                                                                                                    isMultiple={false}
                                                                                                    allowEmpty={true}
                                                                                                    fieldName=''
                                                                                                    disabled={!canEdit}
                                                                                                    onChange={(value) => {
                                                                                                        line.departure_city = parseInt(value)
                                                                                                        values.line_ids[index].departure_city = parseInt(value)
                                                                                                        formRef?.current?.validateForm()
                                                                                                    }}
                                                                                                    defaultValue={values.line_ids[index].departure_city} />
                                                                                                <div className='text-danger'>
                                                                                                    <ErrorMessage name={`line_ids[${index}].departure_city`} component="div" />
                                                                                                </div>
                                                                                            </td>

                                                                                            <td>
                                                                                                <AzkSelect
                                                                                                    compID={"az_arrival_city_select" + index}
                                                                                                    modelName="res.city"
                                                                                                    valueField="id"
                                                                                                    titleField="name"
                                                                                                    dataDomain=''
                                                                                                    isMultiple={false}
                                                                                                    allowEmpty={true}
                                                                                                    fieldName=''
                                                                                                    disabled={!canEdit}
                                                                                                    onChange={(value) => {
                                                                                                        line.arrival_city = parseInt(value)
                                                                                                        values.line_ids[index].arrival_city = parseInt(value)
                                                                                                        formRef?.current?.validateForm()
                                                                                                    }}
                                                                                                    defaultValue={values.line_ids[index].arrival_city} />
                                                                                                <div className='text-danger'>
                                                                                                    <ErrorMessage name={`line_ids[${index}].arrival_city`} component="div" />
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <Form.Check
                                                                                                    className='p-1'
                                                                                                    id={"need_hotel" + index}
                                                                                                    type="checkbox"
                                                                                                    name={"need_hotel" + index}
                                                                                                    disabled={!canEdit}
                                                                                                    onChange={(value) => {
                                                                                                        line.need_hotel = value.target.checked
                                                                                                        values.line_ids[index].need_hotel = value.target.checked
                                                                                                    }
                                                                                                    }
                                                                                                    defaultChecked={values.line_ids[index].need_hotel === true}
                                                                                                />
                                                                                                <div className='text-danger'>
                                                                                                    <ErrorMessage name={`line_ids[${index}].need_hotel`} component="div" />
                                                                                                </div>
                                                                                            </td>

                                                                                            <td>
                                                                                                <Form.Check
                                                                                                    className='p-1'
                                                                                                    id={"need_visa" + index}
                                                                                                    type="checkbox"
                                                                                                    name={"need_visa" + index}
                                                                                                    disabled={!canEdit}
                                                                                                    onChange={(value) => {
                                                                                                        line.need_visa = value.target.checked
                                                                                                        values.line_ids[index].need_visa = value.target.checked
                                                                                                    }
                                                                                                    }
                                                                                                    defaultChecked={values.line_ids[index].need_visa === true}
                                                                                                />

                                                                                                <div className='text-danger'>
                                                                                                    <ErrorMessage name={`line_ids[${index}].need_visa`} component="div" />
                                                                                                </div>
                                                                                            </td>

                                                                                            <td><AsyncPaginate className='rounded-0 col-12'
                                                                                                placeholder="Select an option"
                                                                                                loadOptions={loadTypeOptions}
                                                                                                onChange={(value) => {
                                                                                                    line.purpose = value?.value
                                                                                                    values.line_ids[index].purpose = value?.value
                                                                                                    formRef?.current?.validateForm()
                                                                                                }}
                                                                                                defaultValue={{ value: values.line_ids[index].purpose, label: values.line_ids[index].purpose }}
                                                                                                value={{ value: values.line_ids[index].purpose, label: values.line_ids[index].purpose }}
                                                                                                id={`line_ids[${index}].purpose`} name={`line_ids[${index}].purpose`}
                                                                                            />
                                                                                                <div className='text-danger'>
                                                                                                    <ErrorMessage name={`line_ids[${index}].purpose`} component="div" />
                                                                                                </div>
                                                                                            </td>

                                                                                            <td>
                                                                                                <Form.Control
                                                                                                    className='p-1'
                                                                                                    id={"passport_visa_info" + index}
                                                                                                    type="text"
                                                                                                    name={"passport_visa_info" + index}
                                                                                                    disabled={!canEdit}
                                                                                                    onChange={(value) => {
                                                                                                        line.passport_visa_info = value.target.value
                                                                                                        values.line_ids[index].passport_visa_info = value.target.value
                                                                                                    }}
                                                                                                    defaultValue={values.line_ids[index].passport_visa_info}
                                                                                                />

                                                                                                <div className='text-danger'>
                                                                                                    <ErrorMessage name={`line_ids[${index}].passport_visa_info`} component="div" />
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                {(index > 0 || values.line_ids.length > 0) && <button
                                                                                                    name={`line_ids[${index}].min` + 'delete_line'}
                                                                                                    title="delte"
                                                                                                    type="reset"
                                                                                                    onClick={() => { remove(index) }}
                                                                                                    className='btn btn-light btn-light-info p-1 px-2'
                                                                                                ><i className="bi bi-trash p-0"></i></button>
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                    <tr>
                                                                                        <td>
                                                                                            <button
                                                                                                title="Add entry"
                                                                                                type='submit'
                                                                                                className='btn btn-light btn-light-info mx-5 btn-sm'
                                                                                                onClick={() => {
                                                                                                    is_submitting_form = false
                                                                                                    if (formRef?.current?.isValid) {
                                                                                                        push({
                                                                                                            departure_city: '',
                                                                                                            arrival_city: '',
                                                                                                            need_hotel: false,
                                                                                                            need_visa: false,
                                                                                                            purpose: '',
                                                                                                            passport_visa_info: '',
                                                                                                        })
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <i className="fa fa-plus fs-2" aria-hidden="true"></i>
                                                                                                <span className='indicator-label'>Add new Entry</span>
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            )}
                                                                        </FieldArray>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                    </Row >
                                                    {/* <Row className="m-0 my-2">
                                                        <Col lg="4" md="4" sm="6" xs="12">
                                                            <Form.Group className='mb-2'>
                                                                <Form.Label>
                                                                    Approver Comment
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled={!canValidate}
                                                                    type='text'
                                                                    className="p-1"
                                                                    name="approver-comment"
                                                                    id='approver-comment'
                                                                    onChange={(value) => {
                                                                        setFieldValue('comment', value.target.value)
                                                                    }}
                                                                    value={values.comment}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row > */}
                                                    </fieldset>

                                                    {/* begin::Actions */}
                                                    <div className='py-5 d-flex justify-content-end'>
                                                        {canValidate || canEdit &&
                                                            <button
                                                                title="Submit for approval"
                                                                type='submit'
                                                                onClick={() => {
                                                                    is_submitting_form = true
                                                                    submit_validation_transaction = true
                                                                }}
                                                                className='btn btn-light btn-light-info mx-5 btn-sm'
                                                                disabled={!canEdit}>
                                                                <i className="fa fa-podcast fs-2" aria-hidden="true"></i>
                                                                <span className='indicator-label'>Submit</span>
                                                            </button>
                                                        }
                                                        
                                                        {!canValidate && canEdit &&
                                                            <button
                                                                title="Save Draft"
                                                                type='submit'
                                                                onClick={() => {
                                                                    is_submitting_form = true
                                                                    submit_validation_transaction = false
                                                                }}
                                                                className='btn btn-light btn-light-info mx-5 btn-sm'
                                                                disabled={!canEdit}>
                                                                <i className="fa fa-podcast fs-2" aria-hidden="true"></i>
                                                                <span className='indicator-label'>Save</span>
                                                            </button>
                                                        }

                                                        {canEdit && id &&
                                                            <button
                                                                title="Delete"
                                                                type='reset'
                                                                onClick={() => {
                                                                    deleteTransaction()
                                                                }}
                                                                className='btn btn-light btn-light-danger mx-5 btn-sm'
                                                                disabled={!canEdit}>
                                                                <i className="fa fa-trash fs-2" aria-hidden="true"></i>
                                                                <span className='indicator-label'>Delete</span>
                                                            </button>
                                                        }

                                                        {!canValidate && <>
                                                            <button
                                                                title="Discard & back"
                                                                type='reset'
                                                                onClick={() => cancel()}
                                                                className='btn btn-light btn-active-light-primary me-3 mx-5 btn-sm'
                                                                data-kt-users-modal-action='cancel'
                                                            >
                                                                <KTIcon iconName='exit-left' className='fs-2' />
                                                                <span className='indicator-label'>Discard</span>

                                                            </button>
                                                        </>
                                                        }
                                                    </div>
                                                    {/* end::Actions */}
                                                </Row>
                                            </>)}
                                    </Form>
                                </>
                            )
                        }}
                    </Formik>
                </Col>
                <Col md='12' lg='3' xl='3'>
                    {id && (<AzkStatusRibbon transactionId={parseInt(id)} modelName={MODEL_NAME}
                        current={transactionState} currentName={transactionStateName} />
                    )}
                    {employee && <AzkChatter transactionClosed={transactionClosed} isDrawer={true} modelName={MODEL_NAME} itemID={objectId} hitSend={hitSend} sendMessagesCallback={handleResultOfsubmit} />}
                </Col>
            </Row>
        </>
    )
}

export default TransactionViewform;
